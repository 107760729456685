* {
    padding: 0;
    margin: 0;
    font-family: "Outfit", sans-serif;
}

.MainSection {
    margin: auto;
    margin-top: 1em;
    width: 95%;
    background-color: rgb(255, 255, 255);
    border-radius: 12px;
    position: relative;
    padding: 1em;
    min-height: 100vh;
    box-shadow: #000000 0px 0px 10px;
}

.Title {
    font-size: 30px;
    font-weight: 600;
}

.btnControlPanel {
    display: flex;
    margin-top: 10px;
    padding: 10px;
    border-radius: 8px;
    background: #ffaa2a;
}

.SelectTypeBtnContainer {
    margin-right: 20px;
}

.ControlBar {
    margin-top: 15px;
    padding: 10px;
    border-radius: 8px;
    background-color: #fdae10;
}

.SeachUser {
    right: 1em;
    font-size: 22px;
    border-radius: 5px;
    text-align: left;
    outline: none;
}

.SelectTypeBtn {
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #751917;
    color: white;
    border: none;
    padding: 6px 14px;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    display: inline-flex;
    /* Ensure it's a flex container to center text and icon */
    align-items: center;
    /* Vertically centers text and icon */
}

.SelectTypeIcon {
    font-size: 20px;
    margin-top: 6px;
    margin-right: 10px;
}

.ListUser{
    margin-top: 20px;
}

.UserTable {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.UserTable td,
.UserTable th {
    border: 1px solid #ddd;
    padding: 8px;
}

.UserTable tr:nth-child(even) {
    background-color: #f2f2f2;
}

.UserTable tr:hover {
    background-color: #ddd;
}

.UserTable th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #faaf1a;
    color: #5e3737;
}

.EditUserBtn {
    text-align: center;
}

.EditUserBtn:hover {
    cursor: pointer;
}

.ProductTypeFieldset{
    display: flex;
    width: 100%;
    padding: 10px;
    color: #741715;
    font-weight: 600;
}

.Typelable {
    font-size: 18px;
    margin-left: 5px;
}

.TypeFieldset {
    margin-top: 10px;
    display: flex;
}

.TypeFieldset legend{
    margin-left: 20px;
    font-size: 18px;
    font-weight: 600;
}

.AddContainerBtn {
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #ef4c26;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    display: inline-flex;
    /* Ensure it's a flex container to center text and icon */
    align-items: center;
    /* Vertically centers text and icon */
}

.ProdBtnContainer {
    margin-right: 10px;
}

.BtnControlBar {
    display: flex;
    margin-top: 15px;
    padding: 10px;
    border-radius: 8px;
    background-color: #fdae10;
}

.TableInputDiv {
    display: flex;
    align-items: center;
    margin: 0;
    width: 98%;
}

.TableInputDiv select {
    padding: 3px;
    font-size: 18px;
    width: 100%;
    border-radius: 3px;
    margin-bottom: 5px;
    transform: translateY(10%);
}

.activeBtn {
    background-color: #ef2626; /* Light gray or your preferred active color */
}

.errorInputDiv {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ef2626 !important;
    box-sizing: border-box;
    margin-top: 5px;
}

.TopDiv {
     position: relative;
}

.RefreshBtnContainer {
    position: absolute;
    right: 12px;
    top: 0;
}

.RefreshIcon {
    font-size: 30px;
    color: var(--dash-primary-button-background-color);
    cursor: pointer;
}