.MainSection{
    margin: auto;
    width: 98%;
    border: 5px black solid;
    border-radius: 30px;
}

.PurposeDiv {
    margin: auto;
    width: 70%;
    margin-bottom: 30px;
    line-height: 60px;
    text-align: center;
}

.PurposeGroup{
    justify-content: center;
    text-align: center;
}

.PurposeTitle {
    margin-top: 50px;
    margin-bottom: 0px;
    font-size: 35px;
}

.SubPurposeTitle {
    margin-bottom: 3px;
    font-size: 23px;
    color: #996300;
}

.SubPurposePoint {
    margin: 0;
    margin-left: 130px;
    display: inline-block;
    text-align: left;
    font-size: 23px;
    color: #996300;
}

@media screen and (max-width: 760px) {
    .PurposeDiv {
        margin: auto;
        width: 80%;
        margin-bottom: 30px;
        line-height: 50px;
        text-align: center;
    }

    .SubPurposePoint {
        margin: auto;
        margin-left: 0px;
        display: inline-block;
        text-align: center;
        font-size: 23px;
    }

}