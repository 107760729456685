.MainSection {
    margin: auto;
     width: 100%;
}

.DescriptionMain{
    margin: auto;
    margin-top: 50px;
}

.DescriptionMainGroup {
    margin: auto;
    margin-top: 120px;
    margin-bottom: 120px;
    width: 70%;
}

.DescriptionGroup{
    margin: auto;
    margin-top: 25px;
    margin-bottom: 80px;
    text-shadow: 0 0 20px #000000, 0 0 10px #000000;
}

.DescriptionGroup p{
    color: #ffffff;
}

.DescriptioMainTitle {
    font-size: 50px;
    font-weight: bold;
}

.SubDescriptionTitle {
    font-size: 30px;
    font-weight: bold;
}

.DescriptionMessage {
    font-size: 20px;
    font-weight: bold;
}