.darkpannel {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: center;
}

.AddDiv {
    position: relative;
    /* Make the div relative so that we can position the logo inside it */
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
    background-color: #ffffff;
    width: 330px;
    
}

.Title {
    font-size: 23px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-left: 3px;
}

.baseInfoDiv {
    background-color: #faaf1a;
    border-radius: 8px;
    padding: 10px;
}

.baseInfoItem {
    margin-bottom: 5px;
}

.baseInfoItem input[type="date"] {
    margin: 0;
    width: 98%;
    /* Set the same width as the textboxes */
    border: 1px solid #ccc;
    /* Border similar to textboxes */
    border-radius: 4px;
    /* Rounded corners */
    font-size: 16px;
    /* Same font size */
    background-color: #fff;
    /* White background for consistency */
    color: #000000;
}

.baseInfoItem input[type="text"] {
    margin: 0;
    width: 100%;
    /* Set the same width as the textboxes */
    border: 1px solid #ccc;
    /* Border similar to textboxes */
    border-radius: 4px;
    /* Rounded corners */
    font-size: 16px;
    /* Same font size */
    background-color: #fff;
    /* White background for consistency */
    color: #000000;
}


.baseInfoItem label {
    margin-left: 2px;
    margin-bottom: 2px;
    font-size: 16px;
    font-weight: 500;
}

.baseInfoItem select {
    padding: 3px;
    font-size: 18px;
    width: 100%;
    border-radius: 3px;
    margin-bottom: 5px;
}

.ButtonsDiv {
    display: flex;
    width: 100%;
    margin-top: 10px;
    justify-content: center;
    justify-content: space-between;
}

.BottomBtn {
    width: 48%;
    background-color: #751917;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
}

.BottomBtn i {
    margin-top: 2px;
    margin-right: 8px;
    font-size: 22px;
    color: #ffffff;
}