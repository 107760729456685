@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+HK:wght@500&display=swap');

* {
    padding: 0;
    margin: 0;
    font-family: "Noto Sans HK", sans-serif;
}

.MainSection {
    width: 100%;
    margin: auto;
}

.NavbarMenu {
    background-color: #231C1A;
    margin: auto;
    text-align: center;
    margin-top: 8px;
    height: 80px;
    width: 100%;
}

.MediaMenu {
    display: none;
}

.NavbarMenu ul {
    position: absolute;
    line-height: 80px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.NavbarMenu li {
    display: inline;
    margin-right: 2vw;
}

.active {
    color: white;
    text-decoration: none;
    font-size: 25px;
    padding: 5px 5px;
}

.active:hover {
    text-decoration: underline;
    color: rgb(0, 0, 0);
    background-color: aliceblue;
    border-radius: 8px;
    padding: 8px 10px;
}

.LogoImg{
    width: 300px;   /* Fixed width */
    height: 200px;  /* Fixed height */
    margin: auto;
}

.Slogan {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
}

.Slogan p {
    font-size: 50px;
    font-weight: 900;
    color: rgb(252, 71, 95);
    animation-name: SloganColor;
    animation-duration: 2.3s;
}

@keyframes SloganColor {
    from {color: rgba(253, 36, 65, 0);}
    to {color: rgb(252, 71, 95);}
  }

@media screen and (max-width: 760px) {

    .MediaMenu {
        margin: auto;
        padding-top: 15px;
        display: inline-block;
        cursor: pointer;
        background-color: transparent;
        color: white;
        font-size: 45px;
    }

    #navMenu {
        display: none;
    }

    #navMenu.open {
        display: block;
    }

    .MediaNavSection {
        background-color: #231C1A;
        margin: auto;
        text-align: center;
        margin-top: -2px;
        width: 100%;
        padding-bottom: 15px;
    }

    .Mactive {
        color: white;
        text-decoration: none;
        font-size: 25px;
        padding: 2px 5px;
    }

    .Mactive:hover {
        text-decoration: underline;
        color: rgb(0, 0, 0);
        background-color: aliceblue;
        border-radius: 8px;
        padding: 2px 10px;
    }
}