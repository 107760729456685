.MainSection{
    display: flex;
    margin: auto;
    width: 100%;
    justify-content: space-between;
    background-color: rgb(255, 255, 255);
}

.ContactCenter{
    width: 100%;
    margin: auto;
    padding-top: 45px;
    display: flex;
    justify-content: center;
}

.ContactInfo {
    width: 15%;
    text-align: center;
}

.IMG {
    height: 50%;
    width: 110px;
}

.ContactMessage{
    margin-top: 20px;
}

.ContactMessage p{
    font-size: 19px;
    font-weight: bold;
}


.ContactMessage a{
    text-decoration: none;
    color: black;
}

@media screen and (max-width: 760px) {
    .IMG {
        height: 110px;
        width: 110px;
    }

    .ContactInfo {
        width: 100%;
        margin-bottom: 70px;
        text-align: center;
    }

    .ContactCenter{
        flex-direction: column;

    }
}



