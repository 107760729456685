.MainSection {
    background-color: var(--dash-page-background-color);
    border-radius: 8px;
    min-height: 75vh;
    padding: 8px;
}

.MainChartDiv { 
    display: flex;
    flex-wrap: nowrap;
}

.AllChartDiv {
    display: flex;
    flex-wrap: wrap;
}

.EachDisplayChartDiv {
    width: 600px;
    padding: 20px;
}

.EachDisplayChartDiv p{
    font-weight: 600;
    margin-bottom: 5px;
}


.DataRangeControlDiv {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 10px;
}

.DataRangeControlDiv button {
    font-size: 18px;
    background-color: var(--dash-page-background-color);
    padding: 6px 12px;
    border: none;
    border-radius: 2px;
    margin-right: 8px;
}

.barChart {
    width: 680px;
    height: 400px;
    padding: 10px;
    background-color: var(--dash-each-sub-div-background-color);
    border-radius: 8px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}

.ShopCodeLiseDiv {
    padding: 10px;
    width: 36%;
    max-height: 105vh;
    overflow-y: auto;
}

.ShopTable {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 98%;
    margin-bottom: 20px;

}

.ShopTable td,
.ShopTable th {
    border: 1px solid #ddd;
    padding: 8px;
}

.ShopTable tr:nth-child(even) {
    background-color: #f2f2f2;
}

.ShopTable tr:hover {
    background-color: #ddd;
}

.ShopTable th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #ffffff;
    color: #000000;
}