.MainSection {
    position: relative;
    width: 80%;
    padding: 20px;
    min-height: 91vh;
    margin: auto;
    margin-top: 5px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    max-height: 91vh;
    overflow: hidden;
    overflow-y: auto;
}

.updateLogTitle{
    font-size: 22px;
    font-weight: 600;
}


.UpdateLogTable {
    margin-top: 20px;
    border-radius: 8px;
}

.LogTable {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;

    width: 100%;
}

.LogTable td,
.LogTable th {
    border: 1px solid #ddd;
    padding: 8px;
}

.LogTable tr:nth-child(even) {
    background-color: #f2f2f2;
}

.LogTable tr:hover {
    background-color: #ddd;
}

.LogTable th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #faaf1a;
    color: #5e3737;
}

.UpdateLogTable a{
    font-size: 16px;
    color: black;
    text-decoration: none;
    margin-left: 0px;
}

.version {
    margin-top: 5px;
    font-size: 16px;
    font-weight: 500;
    color: #353535;
}

.UpdatePatchLogDiv {
    margin-top: 20px;
    border-radius: 8px;
    border: 3px #353535 solid;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.UpdatePatchLogDetails {
    padding: 10px;
    font-size: 16px;
    color: #000000;
    background: #e7e7e7;
    border-radius: 5px;
}

.UpdateContentSection {
    padding: 10px;
    border-bottom: 2px #000000 solid;
}

.UpdateContentDivider {
    padding: 10px;
}

.SelectPatchLog {
    position: absolute;
    width: 120px;
    right: 10px;
    top: 10px;
}

.SelectPatchLog label{
    font-size: 18px;
    font-weight: 500;
    color: #353535;
}

.SelectPatchLog select{
    width: 100px;
    height: 30px;
    font-size: 16px;
    font-weight: 500;
    color: #353535;
    border-radius: 5px;
    border: 1px #353535 solid;
}

.UpdatePatchLogDetails strong {
    margin-right: 5px;
}
