@import url('https://fonts.googleapis.com/css2?family=Chocolate+Classical+Sans&family=Jersey+25&family=Teko:wght@300..700&display=swap');

.MainSection {
    min-height: 85vh;
    margin: auto;
    background-color: var(--dash-page-background-color);
    border-radius: 8px;
    padding: 10px;
}

.TopDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.PageTitle {
    margin-left: 5px;
    margin-top: 10px;
    font-size: 26px;
    font-family: "Jersey 25", sans-serif;
    letter-spacing: 2.5px;
    font-weight: 600;
    color: var(--dash-page-title-text-color);
}

.dividingline{
    width: 100%;
    height: 1px;
    background-color: #ffffff;
    margin-top: 10px;
    margin-bottom: 10px;
}

.Functiondividingline {
    width: 100%;
    height: 1px;
    background-color: #000000;
    margin-top: 10px;
    margin-bottom: 10px;
    transform: translateY(10px);
}

.FunctionList {
    margin-top: 20px;
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    background: var(--input-div-background-color);
    border: #b9b9b9 1px solid;
    border-radius: 8px;
}

.FunctionItem {
    margin-right: 35px;
    margin-bottom: 35px;
    width: 200px;
    height: 210px;
    background-color: #ffffff;
    border-radius: 20px;
    padding: 20px;
    box-shadow:  0 0 8px #777879;
}

.FunctionIcon {
    transform: translateY(-45px);
    display: flex;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
}

.FunctionIcon i{
    color: #ffffff;
    font-size: 20px;
}

.FunctionName {
    font-family: "Jersey 25", sans-serif;
    letter-spacing: 1.5px;
    font-size: 22px;
    font-weight: 600;
    color: #2d3133fa;
    transform: translateY(-30px);
}

.FunctionControlDiv {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

}

.FunctionDesc { 
    align-content: center;
    height: 55px;
    font-size: 15px;
}

.LinkDiv {
    width: 50%;
}

.FunctionControlDiv a{
    margin: 0px;
    padding: 0px;
}

.FunctionBtn{
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 30px;
    background-color: #01679bfa;
    border-radius: 5px;
    color: #F4F6FF;
    border: none;
    cursor: pointer;
}

.FunctionStatusDiv {
    margin-right: 15px;
    text-align: center;
}

.functionStatus {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #2d3133fa;
}

.FunctionBtn:hover {
    background-color: #333446;
    color: #fffffffa;
}

.SelectionDiv {
    margin-top: 20px;
}

.TypeBtn {
    margin-right: 10px;
    border-radius:  8px;
    background-color: #741715;
    color: #ffffff;
    padding: 5px 10px;
    font-size: 16px;
    font-weight: 600;
    box-shadow: #000000 0px 0px 3px;
}