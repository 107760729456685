.PageSection {
    width: 100%;
    background-color: #ffffff;
    min-height: 86vh;
}

.ProcessBarDiv {
    width: 96%;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-items: center;
    margin-bottom: 50px;
}

.ProcessBarDiv p{
    width: 100%;
    margin: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    flex-direction: column;
    justify-self: start;
    align-items: center;
    font-weight: 600;
}

.MainSection {
    width: 98%;
    margin: auto;
}

.BasicInfoDiv {
    width: 98%;
    margin: auto;
    min-height: 58vh;
    border: 2px #cacbcb solid;
    border-radius: 8px;
    background-color: #fafafa;
}

.BasicFormTitle {
    width: 130px;
    height: 45px;
    background-color: #016fc5;
    display: flex;
    align-items: center;
    border-bottom: 2px #cacbcb solid;
    border-radius: 8px;
    transform: translate(18px, -23px);
}

.BasicFormTitle p {
    margin-left: 15px;
    color: #ffffff;
    font-size: 20px;
    font-weight: 600;
}

.BasicFormContent {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}

.FormLabel {
    font-size: 15px;
    font-weight: 500;
    margin-top: 10px;
}

.EachInputField {
    width: 330px;
    margin-bottom: 10px;
}

.DatePicker {
    background-color: #ffffff;
    width: 100%;
    height: 40px;
    font-size: 16px;
}

.FormInput input[type="text"] {
    width: 100%;
    height: 40px;
    font-size: 16px;
}

.SeachShopTableDiv {
    width: 510px;
    background-color: #ffffff;
    border: 1px #cacbcb solid;
    border-radius: 5px;
    padding: 10px;
}

.SeachShopTable thead{
    border: 2px #000000 solid;
}

.SeachShopTable th {
    border-bottom: 1px solid #7e7e7e;
}

.SeachShopTable tr {
    height: 20px;
    text-align: left;    
}

.SeachShopTable td {
    height: 30px;
    cursor: pointer;
}

.SeachShopTable tr:nth-child(even) {
    background-color: #f2f2f2;
}

.SeachShopTable tr:hover {
    background-color: #ddd;
}

.ButtonControlDiv {
    position: relative;
    min-height: 60px;
    margin-top: 20px;
    display: flex;
}

.ButtonControlDiv a{
    text-decoration: none;
    color: #ffffff;

}

.ReturnToFunctionDiv {
    position: absolute;
    width: 110px;
    display: flex;
    background-color: #016fc5;
    padding: 8px 6px;
    border-radius: 5px;
    left: 20px;
}

.ReturnToFunctionDiv p {
    margin-left: 5px;
    font-size: 25px;
    font-weight: 600;
    color: #ffffff;
}
.ReturnIcon {
    font-size: 35px;
    display: flex;
    color: #ffffff;
}

.PreviousStepDiv {
    position: absolute;
    width: 130px;
    display: flex;
    background-color: #016fc5;
    padding: 8px 6px;
    border-radius: 5px;
    left: 20px;
    cursor: pointer;
}

.PreviousStepDiv p {
    margin-left: 5px;
    font-size: 25px;
    font-weight: 600;
    color: #ffffff;
}
.PreviousStepDiv {
    font-size: 35px;
    display: flex;
    color: #ffffff;
}

.NextStepDiv {
    position: absolute;
    width: 130px;
    display: flex;
    background-color: #016fc5;
    padding: 8px 6px;
    border-radius: 5px;
    right: 20px;
    cursor: pointer;
}

.NextStepDiv p {
    margin-left: 5px;
    font-size: 25px;
    font-weight: 600;
    color: #ffffff;
}

.NextStepIcon {
    font-size: 35px;
    display: flex;
    color: #ffffff;
}

.AreadyFilterDiv {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    padding-top: 20px;
    padding-left: 25px;
}

.EachFileData {
    display: flex;
    border: 2px #cacbcb solid;
    border-radius: 8px;
    padding: 3px 5px;
    margin-right: 10px;
    margin-bottom: 5px;
    align-items: center;
}

.EachFileData h3{
    margin-left: 5px;
    margin-right: 10px;
    font-size: 0.9rem;
    font-weight: 600;
}

.EachFileData p {
    margin-right: 3px;
    font-size: 0.9rem;
}

.SelectRepairTypeDiv {
    width: 98%;
    margin: auto;
    min-height: 22vh;
    border: 2px #cacbcb solid;
    border-radius: 8px;
    background-color: #fafafa;
    margin-bottom: 40px;
}

.EquipmentOptionDiv {
    border: none;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
}

.eachEquipmentOption {
    width: 15%;
    display: flex;
    margin-bottom: 5px;
}

.eachEquipmentOption label {
    margin-left: 8px;
    font-size: 16px;

}

.EquipmentListDiv {
    width: 98%;
    margin: auto;
    min-height: 20vh;
    border: 2px #cacbcb solid;
    border-radius: 8px;
    background-color: #fafafa;
}

.EquipmentTableDiv {
    height: 60vh;
    overflow-y: auto;
}

.EquipmentTable {
    width: 97%;
    margin: auto;
    margin-bottom: 20px;
    border-collapse: collapse;
}

.EquipmentTable tr:nth-child(even) {
    background-color: #f2f2f2;
}

.EquipmentTable th {
    border: 2px #cacbcb solid;
    text-align: left;
    padding: 10px;
    background-color: #003e77;
    color: #ffffff;
}

.EquipmentTable td {
    border: 2px #cacbcb solid;
    padding: 10px;
}

.EquipmentTable img{
    width: 160px;
    height: 160px;
    cursor: pointer;
}

.FixOptionIcon {
    text-align: center;
    font-size: 65px;
    color: #202020;
    cursor: pointer;
}

.SelectRepairOptionDetailDiv {
    width: 98%;
    margin: auto;
    min-height: 10vh;
    border: 2px #cacbcb solid;
    border-radius: 8px;
    background-color: #fafafa;
    margin-bottom: 10px;
}

.OtherOptionDiv {
    width: 80%;
    margin-left: 10px;
    margin-bottom: 20px;
}

.OtherOptionDiv label{
    margin-left: 3px;
}

.OtherOptionDiv input[type="text"] {
    height: 40px;
    border-radius: 5px;
    font-size: 18px;
}

.UploadDetailDiv {
    display: flex;
    width: 98%;
    margin: auto;
    min-height: 45vh;
    border: 2px #cacbcb solid;
    border-radius: 8px;
    background-color: #fafafa;
    margin-bottom: 20px;
    overflow-y: hidden;
}

.RepairTextInputBox {
    width: 45%;
    padding: 10px;
    border-radius: 8px;
    background-color: #fafafa;
}

.RepairTextInputBox p {
    margin-left: 8px;
    font-size: 16px;
}

.RepairTextInputBox h3 {
    margin-top: 5px;
}

.RepairTextInputBox textarea {
    width: 98%;
    height: 80%;
    border: 2px #cacbcb solid;
    border-radius: 8px;
    font-size: 18px;
    resize: none;
}

.UploadFileBox {
    position: relative;
    width: 50%;
    padding: 20px;
    border-radius: 8px;
    background-color: #fafafa;
}

.DispalyImageDiv {
    width: 90%px;
    height: 45vh;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    border-radius: 8px;
    border: 2px #cacbcb solid;
}

.DispalyImageDiv video{
    width: 96%;
    height: 96%;
    object-fit: contain;
    margin: auto;

}

.DispalyImageDiv img {
    margin: auto;
    width: 100%;
    object-fit: contain;
}

.UpdateImageControlPanel {
    position: absolute;
    display: flex;
    bottom: 25px;
    left: 30px;
    background-color: #ffffff;
    border-radius: 8px;
    border: 2px #cacbcb solid;
    justify-self: center;
    justify-content: center;
    justify-items: center;
}

.UpdateImageBtn {
    width: 160px;
    height: 40px;
    background-color: #016fc5;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8px 12px 8px 12px;
    cursor: pointer;
    
}

.UpdateImageBtn label {
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
}

.UpdateImageBtn input[type="file"] {
    display: none;
}

.PageHeader {
    position: relative;
    width: 100%;
    height: 60px;
    display: flex;

    align-items: center;
}

.ReturnToFunctionBtnDiv {
    position: absolute;
    top: 10px;
    right: 25px;
}

.ReturnToFunctionBtnDiv a {
    text-decoration: none;
    color: #ffffff;
    margin: 0;
    padding: 0;
}

.returnFunctionBtn {
    width: 110px;
    display: flex;
    background-color: #016fc5;
    padding: 8px 6px;
    border-radius: 5px;
    cursor: pointer;
}

.returnFunctionBtn p{
    margin-left: 5px;
    font-size: 25px;
    font-weight: 600;
    color: #ffffff;
}

.HeaderReturnBtn {
    font-size: 35px;
    display: flex;
    color: #ffffff;
}

.ReturnToStep1Div {
    position: absolute;
    width: 130px;
    display: flex;
    background-color: #016fc5;
    padding: 8px 6px;
    border-radius: 5px;
    left: 20px;
    cursor: pointer;
}

.ReturnToStep1Div p{
    margin-left: 5px;
    font-size: 25px;
    font-weight: 600;
    color: #ffffff;
}
