@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+HK:wght@500&display=swap');

.ContectBox {
    margin: auto;
    margin-top: 80px;
    margin-bottom: 80px;
    border-radius: 8px;
    width: 70%;
    height: 100hv;
    border: 2px solid rgb(255, 255, 255);
}

.Title {
    margin: 30px;
    text-align: left;
    font-size: 30px;
    font-weight: bold;
    color: #ffffff;
}

.Description {
    font-family: "Noto Sans HK", sans-serif;
    margin: 30px;
    text-align: left;
    font-size: 23px;
    font-weight: 300;
    color: #ffffff;
    
}