.MainSection {
    width: 55%;
    margin: auto;
}



.ContactUsBtnDiv {
    margin-top: 60px;
    width: 8em;
    text-align: center;
    border: 3px red solid;
    border-radius: 12px;
    padding: 10px 30px;
    background: linear-gradient(#e72120, pink);
}

.ContactUsBtnDiv2 a{
    font-weight: 600;
    font-size: 1.2em;
    text-decoration: none;
    color: black;
}

.ContactUsBtnDiv:hover {
    border: 3px rgb(31, 31, 31) solid;
    background: linear-gradient(#697c87, #c7c7c7);
}

.ContactInfo {
    border: 3px red solid;
    border-radius: 12px;
    margin-top: 30px;
    padding: 20px;
}

.ContactInfo p{
    margin-left: 10px;
    font-size: 1.3em;
}

.EachContact {
    display: flex;
    flex-wrap: wrap;
    margin-top: 25px;
    margin-bottom: 25px;

}

.EachContact img{
    width: 30px;
    height: 30px;
    object-fit: cover;
}

.formDiv {
    margin-top: 30px;
    border: 3px red solid;
    border-radius: 12px;
}

form {
    padding: 20px;
    width: 80%;
    margin: 0 20px;
}

label {
    display: block;
    margin-bottom: 4px;
}

input[type="text"], textarea {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ddd;
    box-sizing: border-box;
    margin-top: 5px;

}

input[type="submit"] { 
    font-size: 1.2em;
    padding: 5px 80px;
    background-color: #ddd;
    border-radius: 3px;

}

.AllContent {
    resize: none;
}

.inputTextBox {
    background-color: #c0c0c0;
}

.textbox {
    display: flex;
}

.required {
    color: #e72120;
}

.checkbox-group {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    border: 2px red solid;
}

.checkbox-group input {
    margin-right: 5px; /* Adjust the spacing between checkbox and label as needed */
}

.FormTop {
    display: flex;
    margin-top: 40px;
    margin-bottom: 40px;
}

.TwoInOne{
    display: flex;
}

.TwoInOne_Logo {
    flex: 1;
}

.TwoInOne_Logo img{
    width: 80%;
    opacity: 0.3;
}

.TwoInOne_Form {
    flex: 1;
}


@media screen and (max-width: 1300px) {
    .MainSection {
        width: 90%;
        margin: auto;
    
    }

    form {
        padding: 20px;
        width: 80%;
        margin: 0 20px;
    }

    .TwoInOne_Logo {
        display: none;
    }

    .TwoInOne_Form {
        background-image: linear-gradient(rgba(255, 255, 255, 0.76), rgba(255, 255, 255, 0.808)), url("../../global/C533/AboutLogo.png");
        background-repeat: no-repeat;
        background-position: 50% 0;
        background-size: 80%;
    }

}
