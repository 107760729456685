@import url('https://fonts.googleapis.com/css2?family=Chocolate+Classical+Sans&family=Jersey+25&family=Teko:wght@300..700&display=swap');

* {
    margin: 0;
    padding: 0;
}

.MainSection {
    background-color: var(--background-color);
    border-radius: 8px;
    min-height: 75vh;
    padding: 8px;
}

.ReportFilter {
    display: flex;
    flex-wrap: wrap;
    padding: 15px;

}

.EachFilterItem {
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 5px 10px;
    display: flex;
    background-color: var(--input-div-background-color);
    border: #9a9b9c 2px solid;
    border-radius: 8px;
    max-width: 98%;
    overflow: hidden;
}

.EachFilterItem select {
    border: none;
    font-size: 16px;
    font-weight: 600;
    margin-left: 3px;
    transform: translateY(-1px);
}

.EachFilterItem p {
    font-size: 16px;
    font-weight: 600;
    margin-left: 3px;
    transform: translateY(2px);
}

.EachFilterItem label {
    margin-right: 3px;
    transform: translateY(2px);
}

.EachFilterItem img {
    background-color: #ffffff;
    border: 1px solid #919191;
    border-radius: 5px;
    margin-right: 5px;
    width: 25px;
    height: 25px;
    object-fit: contain;
}

.ShopListDiv {
    display: flex;     /* Ensures proper block behavior */
    overflow: hidden;          /* Hide overflowing content */
    text-overflow: ellipsis;   /* Show ellipsis when text overflows */
    white-space: nowrap;       /* Prevent text from wrapping */
    max-width: 100%;          /* Set a maximum width if necessary */
    
}

.EachShopCode {
    margin-right: 4px;
}

.TableTitle th {
    border: 1px black solid;
    padding: 5px;
}

.char {
    font-size: 16px;
    height: 0px;
    text-align: center;

}

.TDeliveryMode {
    width: 120px;
    text-align: left;
}

.TProductsGFV {
    width: 200px;
    text-align: center;
}

.TVendorDiscounts {
    width: 140px;
    text-align: center;
}

.TVendorVoucher {
    width: 140px;
    text-align: center;
}

.TVendorVoucher {
    width: 140px;
    text-align: center;
}

.TPandaboxFee {
    width: 120px;
    text-align: center;
}

.TTotalCommission {
    width: 140px;
    text-align: center;
}

.TBalance {
    width: 140px;
    text-align: center;
}

.ShowDataTr {
    text-align: right;
}

.SubTotalDataTr {
    font-weight: 500;
}

.TableTitle {
    width: 200px;
    text-align: left;
    font-weight: 600;
}

.EachTableDiv {
    padding: 15px;
}

.EachTableDiv table {
    border: 1px solid rgb(0, 0, 0);
    border-collapse: collapse;
    margin-bottom: 10px;
}

.RawDataDiv {
    display: grid;
    padding: 15px;
    grid-template-columns: 800px 800px;
    grid-template-rows: 1fr;
    gap: 10px
}

.RawDataADiv {
    grid-column: 1/1;
    grid-row: 1/1;
    border: #9a9b9c 2px solid;
    border-radius: 8px;
    min-height: 78vh;
    box-shadow: 0px 0px 10px 0px #9a9b9c;
}

.RawDataBDiv {
    grid-column: 2/2;
    grid-row: 1/1;
    border: #9a9b9c 2px solid;
    border-radius: 8px;
    min-height: 78vh;
    box-shadow: 0px 0px 10px 0px #9a9b9c;
}

.TitleDiv {
    display: flex;
    background-color: var(--sub-title-background-color);
    border-radius: 8px;
    width: 200px;
    height: 40px;
    transform: translate(20px, -20px);
    justify-content: space-between;
}

.TitleDiv p {
    margin-left: 10px;
    font-weight: 600;
    color: #ffffff;
    transform: translateY(6px);
}

.RawDataTableDivA {
    height: 70vh;
    overflow-y: auto;
    width: 95%;
    padding: 10px;
}

.RawDataTableDivB {
    height: 70vh;
    overflow-y: auto;
    width: 95%;
    padding: 10px;
}

.RawDataTable {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 98%;
    max-height: 80vh;
    overflow: hidden;
    margin-bottom: 20px;

}

.RawDataTable td,
.RawDataTable th {
    border: 1px solid #ddd;
    padding: 8px;
}

.RawDataTable tr:nth-child(even) {
    background-color: #f2f2f2;
}

.RawDataTable tr:hover {
    background-color: #ddd;
}

.RawDataTable th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #ffffff;
    color: #000000;
}

.ControlBtnDiv {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.SelectionBackBtnDiv {
    position: absolute;
    /* 絕對定位 */
    bottom: 20px;
    left: 20px;
}

.SelectionBackBtn {
    background-color: var(--sub-button-background-color);
    color: #ffffff;
    padding: 12px 24px;
    font-size: 18px;
    font-weight: bold;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
}

/* Optional: Hover effect */
.SelectionBackBtn:hover {
    background-color: var(--sub-button-background-color-hover);
    /* Slightly lighter color on hover */
}

.SelectionBackBtn i {
    font-size: 18px;
    /* Adjust icon size */
    transform: translateY(3px);
}

.SelectionBtnDiv {
    position: absolute;
    /* 絕對定位 */
    bottom: 20px;
    right: 20px;
}

.SelectionBtn {
    background-color: var(--sub-button-background-color);
    color: #ffffff;
    padding: 12px 24px;
    font-size: 18px;
    font-weight: bold;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
}

/* Optional: Hover effect */
.SelectionBtn:hover {
    background-color: var(--sub-button-background-color-hover);
    /* Slightly lighter color on hover */
}

.SelectionBtn i {
    font-size: 18px;
    /* Adjust icon size */
}

.PlatformSelection {
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
    align-content: center;
}

.PlatformSelection label {
    font-size: 16px;
    font-weight: 600;
}

.PlatformSelection select {
    margin-left: 10px;
    padding: 5px 12px;
    font-weight: 500;
    border-radius: 8px;
    transform: translateY(-7px);
    font-size: 16px;
}

.TopControlBtn {
    width: 100%;
    padding-left: 20px;
}