@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+HK:wght@100..900&family=Protest+Strike&family=ZCOOL+KuaiLe&family=ZCOOL+XiaoWei&display=swap');

* {
    font-family: "ZCOOL XiaoWei", sans-serif;
}

.Navbar {

    /* overflow: hidden; */
}

.NavbarMain {
    display: flex;
    text-align: center;
}

.Logo {
    margin-left: 15px;
    width: 8%;
}

.Logo img{
    padding-left: 10px;
    
    object-fit: cover;
}

.NavbarMenu {
    padding-top: 0px;
    padding-right: 18px;
    float: right;
}

.NavbarMenu ul{
    list-style-type: none;
    margin: 0;
    padding: auto;
}

.NavbarMenu li{
    display: inline;
    font-size: 25px;
}

.NavbarMenu a{
    font-weight: bold;
    color: rgb(0, 0, 0);
    border: 2px black solid;
    border-radius: 8px;
    padding: 8px 15px;
    text-decoration: none;
}

.NavbarMenu a:hover{
    background-color: rgb(255, 187, 0);
}

