.DashLayout {
    display: grid;
    grid-template-columns: 220px 1fr;
    grid-template-rows: 80px 1fr;
}

.logo {
    grid-column: 1 / 1;
    grid-row: 1 / 1;
    background-color: #ffffff;
    padding: 8px;
}

.logo img {
    object-fit: contain;
}

.navbar {
    grid-column: 1 / 1;
    grid-row: 2 / 2;
    background-color: #ffffff;
}

.userinfo {
    grid-column: 2 / 2;
    grid-row: 1 / 1;
    background-color: #ffffff;
}

.Outlet {
    grid-column: 2 / 2;
    grid-row: 2 / 2;
    background-color: var(--primary-color);
    padding: 12px;
    border-radius: 5px;
    width: 98%;
    max-height: 87vh;
    overflow-y:auto;
}

.HamburgerMenuDiv {
    background-color: aquamarine;
    cursor: pointer;
}

.humburgerDashLayout {
    display: grid;
    grid-template-columns: 50px 1fr;
    grid-template-rows: 80px 1fr;
}

.EachTypeItem {
    position: absolute;
    display: flex;
    bottom: 0;
    padding-left: 15px;
    align-items: center;
    margin-bottom: 20px;

}

.EachTypeItem a{
    margin: 0;
    padding: 0;
    text-decoration: none;

    display: flex;
    align-content: center;
    color: #262626;
}

.iconStyles {
    font-size: 22px;
    margin-right: 15px;
    margin-top: 8px;
}

.EachTypeItem p{
    margin-top: 4px;
    font-size: 23px;
    font-weight: 500;
    color: #262626;
    transform: translateY(-3px);
}
