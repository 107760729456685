@import url('https://fonts.googleapis.com/css2?family=Chocolate+Classical+Sans&family=Jersey+25&family=Teko:wght@300..700&display=swap');
* {
    margin: 0;
    padding: 0;
}

.MainSectionWrapper {
    position: relative;
    min-height: 75vh;
}

.MainSection {
    background-color: var(--background-color);
    border-radius: 8px;
    min-height: 80vh;
    padding: 8px;
}

.KeywordsDiv {
    margin-top: 30px;
    background-color: var(--input-div-background-color);
    border: #9a9b9c 2px solid;
    border-radius: 8px;
    min-height: 30vh;
}

.TitileDiv {
    display: flex;
    background-color: var(--sub-title-background-color);
    border-radius: 8px;
    border: #9a9b9c 2px solid;
    width: 200px;
    height: 40px;
    transform: translate(20px, -20px);
    justify-content: space-between;
}

.TitileDiv p {
    margin-left: 10px;
    font-weight: 600;
    color: #ffffff;
    transform: translateY(6px);
}

.KeywordsInputDiv {
    padding: 15px;
}

.KeyWordsFieldset {
    width: 75%;
    border-radius: 8px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
}

.KeyWordsFieldset legend {
    margin-left: 3px;
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
}

.KeyWordsFieldset input[type="text"]{
    width: 230px;
    border-radius: 8px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.KeywordPackageFieldset {
    width: 75%;
    border-radius: 8px;
    margin-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
}

.KeywordPackageFieldset button {
    display: flex;
    background-color: #e8f3fb;
    padding: 6px 12px;
    border-radius: 8px;
    border: none;
    margin-right: 10px;
    margin-top: 5px;
    cursor: pointer;
    box-shadow: 4px 0 10px 0 rgba(0, 0, 0, 0.4);
}

.KeywordPackageFieldset button:hover {
    box-shadow: 4px 0 10px 0 rgba(4, 0, 255, 0.4);
}

.AddKeywordButton {
    display: flex;
    background-color: #ffffff;
    padding: 6px 12px;
    border-radius: 8px;
    width: 230px;
    margin-right: 10px;
    margin-top: 5px;
    cursor: pointer;
}

.AddKeywordButton p {
    font-weight: 600;
}

.AddKeywordButton i {
    font-weight: 600;
    font-size: 18px;
    margin-right: 5px;
    transform: translateY(3px);
}

.PackageNameDiv2 {
    display: flex;
    width: 75%;
}

.PackageNameDiv2 input[type="text"] {
    width: 50%;
    height: 45px;
    border-radius: 8px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 16px;
}

.SavePackageButton2 {
    display: flex;
    justify-content: center;
    align-content: center;
    border-radius: 8px;
    width: 45px;
    height: 45px;
    background-color: #ffffff;
    padding: 2px 2px;
    margin-right: 10px;
    transform: translateY(4px);
    box-shadow: 3px 0 10px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.SavePackageButton2 p {
    font-size: 13px;
    font-weight: 600;
}

.SavePackageButton2 i {
    font-weight: 600;
    font-size: 18px;
    transform: translateY(3px);
}

.SavePackageButton2 img {
    object-fit: contain;
    border-radius: 8px;
}

.SavePackageButton2:hover {
    background: linear-gradient(110deg, #2680e7 60%, #1f15ad 10%);
}



.ControlBtnDiv {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ffffff;
    padding: 5px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.SelectionBtnDiv {
    position: absolute;
    /* 絕對定位 */
    bottom: 20px;
    right: 20px;
}

.SelectionBtn {
    background-color: var(--sub-button-background-color);
    color: #ffffff;
    padding: 12px 24px;
    font-size: 18px;
    font-weight: bold;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
}

/* Optional: Hover effect */
.SelectionBtn:hover {
    background-color: var(--sub-button-background-color-hover);
    /* Slightly lighter color on hover */
}

.SelectionBtn i {
    font-size: 18px;
    /* Adjust icon size */
}

.SelectionBackBtnDiv {
    position: absolute;
    /* 絕對定位 */
    bottom: 20px;
    left: 20px;
}

.SelectionBackBtn {
    background-color: var(--sub-button-background-color);
    color: #ffffff;
    padding: 12px 24px;
    font-size: 18px;
    font-weight: bold;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
}

/* Optional: Hover effect */
.SelectionBackBtn:hover {
    background-color: var(--sub-button-background-color-hover);
    /* Slightly lighter color on hover */
}

.SelectionBackBtn i {
    font-size: 18px;
    /* Adjust icon size */
    transform: translateY(3px);
}

.KeywordControlBtnDiv {
    display: flex;
}
