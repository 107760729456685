input[type="checkbox"].toggle {
    opacity: 0;
    position: absolute;
    left: -9000px;
    top: -9000px;
    cursor: pointer;
}

input[type="checkbox"].toggle + label {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
}

input[type="checkbox"].toggle + label::before {
    content: "";
    width: 4em;
    height: 2em;
    background-color: hsl(0, 100%, 90%);
    border-radius: 1em;
    margin-right: .25em;
    transition: 200ms ease-in-out;
}

input[type="checkbox"].toggle + label::after {
    position: absolute;
    left: .2em;
    content: "";
    width: 1.8em;
    height: 1.8em;
    background-color: hsl(0, 100%, 60%);
    border-radius: 1em;
    transition: 200ms ease-in-out;
}

input[type="checkbox"].toggle:focus + label::before {
    outline: 1px solid black;
}

input[type="checkbox"].toggle:checked + label::before {
    background-color: hsl(100, 70%, 90%);
}

input[type="checkbox"].toggle:checked + label::after {
    transform: translateX(100%);
    background-color: hsl(100, 70%, 60%);
}

/* Toggle Button */

