@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+HK:wght@100..900&family=Protest+Strike&family=ZCOOL+KuaiLe&family=ZCOOL+XiaoWei&display=swap');

* {
    font-family: "ZCOOL XiaoWei", sans-serif;
}

.MainSection {
    margin: auto;
    width: 80%;
    min-height: 50vh;
}

.LoginMain {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.LoginDiv {
    align-items: left;
}

.Title {
    font-size: 30px;
    margin-top: 0x;
    margin-bottom: 30px;
    text-align: center;
}

.LoginForm {
    /* padding-top: 50px;
    padding-left: 30px;
    margin-left: 20px;
    border: 2px black solid;
    border-radius: 20px;
    text-align: left;
    width: 30%;
    height: 330px; */
    background-color: #f8f8f8;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 20px;
    max-width: 400px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}

.LoginTitle p {
    font-size: 35px;
    font-weight: bold;

}

.Loginlab {
    /* text-align: left;
    font-size: 20px; */
    position: relative;
    font-size: 1.2em;
    color: #333;
    text-shadow: 1px 1px #ddd;
}

.LoginTextBox {
    width: 90%;
    padding: 5px 8px;
    border-radius: 5px;
    font-size: 22px;
    margin-bottom: 10px;
}

.LoginComboBox {
    width: 100%;
    padding: 5px 8px;
    border-radius: 5px;
    font-size: 22px;
    margin-bottom: 15px;
}

.LoginBtn {
    /* font-family: "ZCOOL XiaoWei", sans-serif;
    font-size: 22px;
    border-radius: 5px;
    background-color: rgb(193, 236, 91);
    padding: 8px 50px; */
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 1.2em;
    padding: 10px;
    width: 100%;
    transition: background-color 0.3s ease;
    transition: 0.25s;
}

.password_toggle_icon {
    position: absolute;
    top: 52%;
    right: 15px;
    cursor: pointer;
}

.LoginBtn2 {
    display: none;
}

.LoginBtn:hover {
    background-color: #0069d9e7;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 105, 217, 1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 105, 217, 1);
    box-shadow: 0px 0px 10px 0px rgba(0, 105, 217, 1);
}

.LoginStatus {
    width: 93%;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 2px rgba(255, 0, 0, 0.027) solid;
    border-radius: 8px;
    padding: 8px 12px;
    background-color: rgba(255, 0, 0, 0.068);
}

.LoginStatus p {
    color: red;
    font-size: 20px;
}

.checkboxdiv {
    display: flex;
    margin-bottom: 10px;
}

.OtherOptionDiv {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 3px;
}

.otheroption {
    font-family: 'Noto Sans HK', sans-serif;
    border: none;
    background-color: #f8f8f8;
    cursor: pointer;
}

.otheroption:hover {
    color: #007bff;
}

.MainSection_V2 {
    margin: auto;
    width: 80%;
    padding: 30px;
    min-height: 50vh;
}

.LoginBodyDiv_V2 {
    position: relative;
    width: 500px;
    margin: auto;
    padding: 20px;
    border: #333 1px solid;
    border-radius: 8px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
}

.BodyTitle_V2 {
    display: flex;   
}

.BodyTitleIcon_V2 {
    margin-top: 6px;
    margin-left: 6px;
    font-size: 26px;
    margin-right: 10px;
    color: #2b2b2b;
}

.BodyTitle_V2 p {
    margin-bottom: 30px;
}

.BodyTitle_V2 p {
    font-family: 'Courier New', Courier, monospace;
    font-size: 30px;
    font-weight: bold;
}

.BodyInput_V2 {
    margin-bottom: 10px;
}

.BodyInput_V2 label {
    font-family: 'Courier New', Courier, monospace;
}

.BodyInput_V2 input[type="text"],
input[type="password"] {
    width: 100%;
    padding: 12px 8px;
    border-radius: 5px;
    font-size: 20px;
    margin-bottom: 10px;
}

.BodyInput_V2 input[type="password"] {
    width: 97%;
}

.AlertMessageDiv_V2 {
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    height: 30px;
}

.AlertMessageIcon {
    font-size: 16px;
    margin-right: 10px;
    color: #c9384b;
    transform: translateY(4px);
}

.AlertMessageDiv_V2 p {
    font-family: 'Courier New', Courier, monospace;
    font-size: 16px;
    color: #c9384b;
}

.BodyButton_V2 {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 50px;
}

.BodyButton_V2 button {
    background-color: #006ead;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 1.2em;
    padding: 10px;
    width: 25%;
    transition: background-color 0.3s ease;
    transition: 0.25s;

}

.BodyButton_V2 button:hover {
    background-color: #0085ad;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 1.2em;
    padding: 10px;
    width: 26%;

    box-shadow: 0px 0px 10px 0px rgba(0, 105, 217, 0.5);
}

.BodyReturnButton_V2 {
    display: flex;
    width: 100%;
    justify-content: flex-start;
}

.BodyReturnButton_V2 button {
    font-family: 'Courier New', Courier, monospace;
    background: #ffffff;
    border: none;
    color: #636363;
    cursor: pointer;
}

.QuickLoginDiv_V2 {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 90px;
    top: 60px;
    left: 15px;
    padding: 12px 12px;
    background-color: #faf9f9;
    border: 1px #333333a8 solid;
    border-radius: 5px;
    justify-content: center;
}

.QuickLoginDiv_V2 label {
    font-size: 16px;
    color: #242424;
}

.QuickLoginDiv_V2 select {
    margin-top: 5px;
    padding: 5px 3px;
    width: 100%;
    font-size: 16px;
    border-radius: 5px;
}




@media screen and (max-width: 760px) {

    .MainSection {
        margin: auto;
        width: 90%;
        min-height: 50vh;
    }

    .LoginForm {
        padding-top: 50px;
        padding-left: 20px;
        padding-bottom: 30px;
        margin-left: 30px;
        border: 2px black solid;
        border-radius: 20px;
        text-align: left;
        width: 80%;
        height: 100%;
    }

    .LoginForm {
        margin-left: 0px;
    }

    .LoginBodyDiv_V2 {
        width: 80%;
    }
}