.ACFunctionListSection {
    width: 98%;
    margin: auto;
}

.ACFunctionListSection hr{
    margin-top: 5px;
    margin-bottom: 5px;
}

.TitleDiv {
    padding-top: 5px;
    padding-left: 10px;
}

.TitleDiv p{
    font-size: 26px;
}

.FuctionList {
    display: flex;
    flex-wrap: wrap;
}

.informationDiv{
    display: flex;
    flex-direction: column
}

.DataTitleDiv {
    margin: 5px;
    width: 180px;
    height: 70px;
}

.DataInfoDiv {
    display: flex;
    margin: 5px;
    background: white;
    width: 180px;
    height: 70px;
    border-radius: 8px;
}

.subinfobox {
    flex: 1;
    margin: 5px;
    text-align: center;
}

.ACFunctionListSection .informationDiv .DataInfoDiv .subinfobox .SubTitleP {
    font-size: 20px;
    color: black; /* More specific selector */
}

.ACFunctionListSection .informationDiv .DataInfoDiv .subinfobox .SubDescP {
    color: black;
    font-size: 16px; /* More specific selector */
}

.eachItem {
    background-color: #1f2937;
    border-radius: 10px;
    margin-right: 2em;
    margin-bottom: 2em;
    border: 2px black solid;
    width: 380px;
}


.eachItem a{
    text-decoration: none;
    color: black;
    height: 50px;
    margin: 0;
}

.eachItemTop{
    display: flex;
    padding: 10px;
}

.eachItemTop p{
    color: white;
}

.imgDiv {
    width: 160px;
    margin-right: 10px;
}

.imgDiv img{
    border-radius: 16px;
}


.ClickBtnDiv {
    background: white;
    margin: 13px;
    padding: 8px;
    border-radius: 6px; 
}

.ClickBtnDiv:hover {
    background: rgba(255, 255, 255, 0.945);
    cursor: pointer;
    margin: 13px;
    padding: 8px;
    border-radius: 6px; 
}

.ClickBtnDiv p{
    text-align: center;
}