.MainSection {
    width: 80%;
    margin: auto;
}

.ImageSliderBody img{
    margin: auto;
    height: 80%;
}

.ImageSliderBody img{
    width: 100%;
}

@media screen and (max-width: 760px) {
    .MainSection {
        width: 100%;
    }

    .MainSection {
        width: 80%;
        margin: auto;
        height: 100%;
    }
    
}