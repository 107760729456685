.MainSection {
    background-color: var(--dash-page-background-color);
    min-height: 85vh;
    padding: 15px;
}

.UserBaseInfomationDiv {
    margin-top: 5px;
    background-color: var(--dash-each-sub-div-background-color);
    border: var(--dash-each-sub-div-border-color) 2px solid;
    border-radius: 8px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
    min-height: 15vh;
    padding: 15px;
    margin-bottom: 30px;
}

.MainTitile {
    display: flex;
    background-color: var(--dash-each-sub-div-title-background-color);
    border-radius: 8px;
    width: 260px;
    height: 40px;
    transform: translate(20px, -30px);
    justify-content: space-between;
} 

.MainTitile p {
    margin-left: 10px;
    font-weight: 600;
    color: var(--dash-each-sub-div-title-color);
    transform: translateY(6px);
}

.MainTitile i {
    margin-left: 10px;
    font-weight: 600;
    color: var(--dash-each-sub-div-title-color);
    transform: translate(-20px, 11px);
}

.MainInputDiv {
    display: flex;
}

.EachDisplayDiv {
    padding: 10px;
    border: var(--dash-each-sub-div-border-color) 0.5px solid;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.EachInputDiv {
    width: 320px;
    margin-bottom: 20px;
}

.UserBaseInfomationDiv button {
    background-color: var(--dash-each-sub-div-title-background-color);
    color: var(--dash-each-sub-div-title-color);
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    margin-right: 10px;
    font-weight: 600;
    cursor: pointer;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}