.MainDiv {
    display: flex;
    min-height: 100vh;
}

.NavBar {
    width: 18em;
}

.Outlet {
    background-color: rgb(246, 248, 255);
    width: 100%;
}
