.TopSection {
  min-height: 5vh;
}

.Basetable {
    margin-left: 20px;
    margin-top: 20px;
    background-color: rgb(255, 255, 255);
    border-collapse: collapse;
    width: 95%;
  }

.Basetable td, .Basetable th {
    border: 1px solid #ddd;
    padding: 8px;
  }

.Basetable tr:nth-child(even){background-color: #f2f2f2;}
.Basetable tr:hover {background-color: #c9c6c6;}

.SelectNoBTN {
    background-color: aliceblue;
    text-align: center;
    font-size: 25px;
    font-weight: 900;
    width: 50px;
    height: 50px;
    border-radius: 5px;
}

.SelectNoBTN:hover {
    cursor: pointer;
    background-color: #ffffff;
    color: black;
}

.detailSection {
  margin-top: 10px;
  width: 96%;
}

input[type="date"] {
  margin-top: 10px;
  margin-left: 20px;
  background-color: #444444;
  padding: 5px;
  color: #ffffff;
  font-size: 18px;
  border: none;
  outline: none;
  border-radius: 5px;
}

::-webkit-calendar-picker-indicator {
  background-color: #ffffff;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}
