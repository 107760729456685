@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Faster+One&family=Outfit:wght@200&display=swap');

.MainSection {
    width: 70%;
    margin: auto;
}

.MainDiv {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
}


.NewsDiv {
    flex: 0.8;
    padding: 5px 10px;
}

.VideosDiv {
    flex: 1;
}

.NewsTitleDiv {
    width: 100%;
    margin: auto;
    border: 1px rgb(151, 15, 15) solid;
    background: black;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 8px;
}

.NewsTitle {
    font-family: "Faster One";
    color: red;
    font-size: 2em;
}

.NewsDataTable {
    width: 100%;
}

.tablebox {
    border-bottom: 2px dashed #05038f;
}


.NewsDataTitle {
    margin: auto;
    padding: 12px 30px;
}

.NewsDataDesc {
    margin: auto;
    font-size: 10px;
    text-align: left;
    text-decoration: none;
}

.NewsDataDesc a {
    color: #1643f8;
    text-decoration: none;
}

.ClassInfo {
    margin-top: 20px;
    margin-bottom: 40px;
}

.SummerCoure {
    display: flex;
}

.SummerCoureSub1 {
    text-align: center;
    width: 50%;
}

.SummerCoureTitle {
    margin-top: 20px;
    margin-bottom: 10px;
}

.SummerCourePTag {
    width: 50%;
    margin: auto;
    font-size: 20px;
    padding: 13px 0px;
    text-align: center;
    border: 0.15em #d81013 solid;
    /* Changed from px to em */
    border-radius: 0.5em;
    /* Adjusted to em for consistency */
    color: #d81013;
}

.SummerCoureImg img {
    border: 3px #d81213 solid;
    box-shadow: 5px 5px 8px rgb(0, 0, 0);
    width: 70%;
}

.Videos {
    width: 100%;
}

.CourseDiv {
    margin: auto;
    margin-top: 20px;
    margin-bottom: 30px;
}

.ThreeInOne {
    display: flex;
    flex-wrap: nowrap;
}

.EachCourseImg {
    flex: 1;
    margin: 5px 15px 5px 15px;
    border: 2px #d81213 solid;
    box-shadow: 5px 5px 12px rgb(0, 0, 0);
}

.CourseDiv2 {
    width: 100%;
    display: flex;
}

.TwoInOne {
    flex: 0.6;
    margin: auto;
}

.CourseDesc {
    flex: 1;
    margin: auto;
}

.EachDescImg {
    width: 50%;
    margin: auto;
    margin-top: 15px;
    margin-bottom: 15px;
    border: 2px #d81013 solid;
}

.EachCourseImg2 {
    width: 50%;
    margin: auto;
    border: 2px #d81013 solid;
}

@media screen and (max-width: 1300px) {

    .MainSection {
        width: 90%;
        margin: auto;
    }

    .MainDiv {
        display: block;
        flex-wrap: wrap;
    }

    .SummerCoure {
        display: block;
    }

    .SummerCoureSub1 {
        text-align: center;
        width: 98%;
    }

    .SummerCoureImg img {
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .NewsDataTitle {
        margin: auto;
        padding: 3px 10px;
    }

    .NewsDataDesc {
        margin: 0;
        text-align: left;
        font-size: 11px;
        text-decoration: none;
    }

    .CourseDiv {
        margin-top: 20px;
        margin-bottom: 30px;
    }

    .ThreeInOne {
        display: block;
    }

    .CourseDiv2 {
        display: block;
    }

    .EachCourseImg2 {
        width: 90%;
        margin: auto;
        border: 2px #d81013 solid;
        margin-bottom: 30px;
    }

}