.progressBarBorder {
    display: flex;
    width: 100%;
    height: 25px;
    padding: 2px;
    border: 2px solid #5e5e5e;
    background-color: aliceblue;
    border-radius: 8px;
    align-items: center;
}

.progress {
    height: 23px;
    width: 100%;
    border-radius: 8px;
    background: repeating-linear-gradient(
        45deg,
        #4caf50,
        #4caf50 10px,
        #66bb6a 10px,
        #66bb6a 20px
    );
}