@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+HK:wght@500&display=swap');

* {
    margin: 0;
    font-family: "Noto Sans HK", sans-serif;
}

/* .MainSection{
    overflow: hidden;
} */

.LogoDiv {
    font-size: 20px;
    margin: auto;
    text-align: center;
    justify-content: center;
    margin-bottom: 25px;
}

.LogoDiv img {
    margin-top: 10px;
    width: 80%;
    margin-bottom: 50px;
}

.LogoutBtn {
    border: 3px black solid;
    color: rgb(0, 0, 0);
    background-color: rgb(255, 174, 0, 1);
    border-radius: 9px;
    padding: 8px 30px;
    text-decoration: none;
}

.LogoutBtn:hover {
    text-decoration: none;
    background-color: rgb(255, 174, 0, 0.8);
}

.MainMenu {
    margin-top: 10px;
}

.EachItem {
    /* display: flex; */
    padding: 5px 20px 5px 30px;
    transition: background-color 0.5s;
}

.EachItem i {
    font-size: 23px;
    margin-right: 10px;
}

.NavBarBtn {
    display: contents;
    margin: auto;
    color: black;
}

.NavBarBtn p{
    color: black;
    text-decoration: none;
    font-size: 22px;
}

.EachItem:hover, .SubEachItem:hover {
    background-color: rgb(202, 202, 202);
}

.EachItem {
    position: relative;
    display: flex;
    width: 80%;
}

.DDEachItem {
    padding: 5px 0px 5px 30px;
    display: flex;
    transition: background-color 0.5s;
}

.DDEachItem i {
    font-size: 23px;
    margin-right: 10px;
}

.DDEachItem{
    width: 100%;
    border: none;
    background-color: white;
}

.DropDownMenu .DDEachItem p {
    font-size: 22px;
}

.DDEachItem:hover {
    background-color: rgb(202, 202, 202);
}

.SubNavBarBtn {
    text-decoration: none;
    margin: auto;
    color: black;    
}

.SubNavBarBtn p{
    padding-left: 10px;
    color: black;
    text-decoration: none;
    font-size: 22px;
}

.SubEachItem {
    padding: 5px 0px 5px 40px;
    display: flex;
    transition: background-color 0.5s;
}

.SubEachItem i {
    font-size: 23px;
    margin-right: 0px;
}



.EachItem:hover .dropdowncontent {display: block;}
.dropdowncontent a:hover {background-color: #ddd;}

@media screen and (max-width: 580px) {

}