:root {
  --primary-color: #ffc146; /* Blue as primary color */
  --secondary-color: #ffaa2a; /* Orange as secondary color */
  --background-color: #ffffff;
  --mina-button-background-color: #751917;
  --sub-button-background-color: #ef4c26;
  --sub-button-background-color-hover: #ff6a3d;
  --button-text-color: #ffffff;
  --page-titile-text-color: #000000;
  --sub-title-background-color: #ffaa2a;
  --table-title-text-color: #5e3737;
  --edit-button-color: #032654;
  --input-div-background-color: #f5f5f5;

  --dash-outlet-background-color: #ffc146;
  --dash-logo-background-color: #ffffff;
  --dash-userinfo-background-color: #ffffff;
  --dash-navbar-background-color: #ffffff;

  --dash-page-background-color: #ffffff;
  --dash-page-title-text-color: #000000;
  --dash-each-sub-div-background-color: #f5f5f5;
  --dash-each-sub-div-border-color: #b9b9b9;
  --dash-each-sub-div-title-background-color: #ffaa2a;
  --dash-each-sub-div-title-color: #000000;
  --dash-dividingline-color: #b9b9b9; 

  --dash-primary-button-background-color: #751917;
  --dash-primary-button-text-color: #ffffff;
  --dash-primary-button-background-color-hover: #8c1f1f;
  --dash-primary-button-text-color-hover: #ffffff;

  --dash-secondary-button-background-color: #ef4c26;
  --dash-secondary-button-text-color: #ffffff;
  --dash-secondary-button-background-color-hover: #ff6a3d;
  --dash-secondary-button-text-color-hover: #ffffff;

}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 

}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--dash-secondary-button-background-color); 
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.character {
  border: none;
  font-size: 20px;
  border-radius: 8px;
 
  /* light theme */
  color: #272729;
  background-color: #f6f5fa;
  box-shadow: 0 2px 0 #e4e2f5;
 
  /* dark theme */
  /* color: #fff;
  background-color: #222;
  box-shadow: 0 2px 0 #444; */
}
