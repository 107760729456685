.DatePicker {
    background-color: #ffffff;
    width: 220px;
    height: 40px;
    outline: none;
    border: none !important;
    border-radius: 8px;
    box-shadow: none !important;
    font-size: 18px;
}

/* Ensure all input elements inside DatePicker also have no borders or shadows */
.DatePicker input {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

/* Remove borders or outlines when focused */
.DatePicker:focus,
.DatePicker input:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

