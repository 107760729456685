.MainSection {
    height: 100%;
    display: flex;
    align-content: center;
}

.returnDiv {
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;

}

.RetrunIcon {
    color: #ffffff;
    font-size: 22px;
    margin-left: 0px;
    margin-right: 12px;

}

.returnDiv a{
    color: #ffffff;
    font-size: 22px;
    font-weight: 600;
    text-decoration: none;
}