.MainSection {
    width: 98%;
    min-height: 50vh;
    margin: auto;
}

.buttonContainer {
    display: flex;
    align-items: center;
    /* Vertically centers the button, optional */
    margin-left: 20px;
    margin-bottom: 20px;
    /* Space between the button and the next element */
}

.ReturnListBtn {
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #741715;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    display: inline-flex;
    /* Ensure it's a flex container to center text and icon */
    align-items: center;
    /* Vertically centers text and icon */
}

.returntoListIcon {
    font-size: 20px;
    margin-top: 6px;
    margin-right: 10px;
}

.SubTitleDiv {
    display: flex;
    /* Enables flexbox layout */
    align-items: center;
    /* Vertically aligns the h1 and i elements */
    justify-content: space-between;
    /* Optional: Adjusts the spacing between them */
    margin-bottom: 20px;
}

.SubTitleDiv h1 {
    margin: 0;
    /* Optional: Remove default margin from the h1 to better align */
    font-size: 22px;
    font-weight: 600;
    color: #741715;
}

.SubTitleDiv i {
    margin-left: 10px;
    font-size: 22px;
    color: #741715;
    /* Adds some space between the h1 and i element */
}

.baseInfoDiv {
    display: grid;
    grid-template-columns: 320px 460px 460px;
    grid-row: 1fr;
    height: 300px;
    width: 85%;
    /* flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; */
}

.userImageDiv {
    position: relative;
    grid-column: 1/1;
    grid-row: 1/1;
    /* width: 18%; */
    height: 280px;
    padding: 15px;
    background-color: #f5f5f5;
    box-shadow: #000000 0px 0px 10px;
    border-radius: 8px;
    margin-right: 20px;
}

.userImageDiv input[type="file"] {
    position: absolute;
    height: 100%;
    width: 100%;
    filter: alpha(opacity=0);
    cursor: pointer;
    opacity: 0;
}



.baseInfo1 {
    grid-column: 2/2;
    grid-row: 1/1;
    width: 90%;
    padding: 15px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: #000000 0px 0px 10px;
}

.baseInfo2 {
    grid-column: 3/3;
    grid-row: 1/1;
    width: 90%;
    padding: 15px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: #000000 0px 0px 10px;
}

.baseInfoItem {
    margin-bottom: 5px;
}

.baseInfoItem input[type="date"] {
    margin: 0;
    width: 98%;
    /* Set the same width as the textboxes */
    border: 1px solid #ccc;
    /* Border similar to textboxes */
    border-radius: 4px;
    /* Rounded corners */
    font-size: 16px;
    /* Same font size */
    background-color: #fff;
    /* White background for consistency */
    color: #000000;
}

.baseInfoItem input[type="text"] {
    margin: 0;
    max-width: 100%;
    /* Set the same width as the textboxes */
    border: 1px solid #ccc;
    /* Border similar to textboxes */
    border-radius: 4px;
    /* Rounded corners */
    font-size: 16px;
    /* Same font size */
    background-color: #fff;
    /* White background for consistency */
    color: #000000;
}

.baseInfoItem input[type="password"] {
    margin: 0;
    width: 97%;
    height: 12px;
    /* Set the same width as the textboxes */
    border: 1px solid #ccc;
    /* Border similar to textboxes */
    border-radius: 4px;
    /* Rounded corners */
    font-size: 16px;
    /* Same font size */
    background-color: #fff;
    /* White background for consistency */
    color: #000000;
}

.errorbaseInfoItem input[type="text"] {
    margin: 0;
    max-width: 100%;
    /* Set the same width as the textboxes */
    border: 1px solid #f70000;
    /* Border similar to textboxes */
    border-radius: 4px;
    /* Rounded corners */
    font-size: 16px;
    /* Same font size */
    background-color: #fff;
    /* White background for consistency */
    color: #000000;
}

.errorbaseInfoItem input[type="password"] {
    margin: 0;
    width: 97%;
    height: 12px;
    /* Set the same width as the textboxes */
    border: 1px solid #ff0000;
    /* Border similar to textboxes */
    border-radius: 4px;
    /* Rounded corners */
    font-size: 16px;
    /* Same font size */
    background-color: #fff;
    /* White background for consistency */
    color: #000000;
}


.baseInfoItem label {
    margin-left: 2px;
    margin-bottom: 2px;
    font-weight: 500;
}

.baseInfoItem select {
    padding: 3px;
    font-size: 18px;
    width: 100%;
    border-radius: 3px;
    margin-bottom: 5px;
}

.SelectTypeInfoDiv {
    display: flex;
}

.SelectTypeBtnContainer {
    display: flex;
    align-items: center;
    /* Vertically centers the button, optional */
    margin-bottom: 20px;
    /* Space between the button and the next element */
    margin-right: 20px;
    margin-top: 10px;
}

.SelectTypeBtn {
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #ef4b25;
    color: white;
    border: none;
    padding: 6px 14px;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    display: inline-flex;
    /* Ensure it's a flex container to center text and icon */
    align-items: center;
    /* Vertically centers text and icon */
}

.SelectTypeIcon {
    font-size: 20px;
    margin-top: 6px;
    margin-right: 10px;
}

.SubSection {
    width: 95%;
    min-height: 15vh;
    margin: auto;
    background: #ffaa2a;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 10px;
}

.SubInfoTitleDiv {
    margin-left: 10px;
    font-size: 14px;
    color: #000000;
    margin-bottom: 15px;
}

.personalInfoDiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.RestPasswordDiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
}

.RestPasswordInfoItem {
    width: 30%;
    padding: 15px;
    background-color: #f5f5f5;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: #000000 0px 0px 10px;
    margin-right: 20px;
}

.personalInfoItem {
    width: 30%;
    padding: 15px;
    background-color: #f5f5f5;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: #000000 0px 0px 10px;
}

.UserAllowanceList {
    margin-top: 20px;
    background-color: #dddddd;
    max-width: 100%;
    overflow-x: auto; /* Allow horizontal scrolling if necessary */
    padding-bottom: 10px; /* Add space below for the scrollbar */
    overflow-y: auto;
}

.UserTable {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%; /* Allow table to expand and take full width */
    table-layout: auto; /* Auto-size the columns based on content */
}

.UserTable td,
.UserTable th {
    border: 1px solid #ddd;
    padding: 8px; /* Adjust padding for better spacing */
    text-align: center; /* Center-align text in cells */

}

.UserTable tr:nth-child(even) {
    background-color: #f2f2f2;
}

.UserTable tr:hover {
    background-color: #ddd;
}

.UserTable th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #741715;
    color: #ffffff;
}

.EditUserBtn {
    text-align: center;
}

.EditUserBtn:hover {
    cursor: pointer;
}

.UserAllowanceInfoDiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
}

.UserAllowanceInfoItem {
    margin-right: 20px;
    width: 40%;
    
    padding: 15px;
    background-color: #f5f5f5;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: #000000 0px 0px 10px;
}

.BottomSection {
    width: 96%;
    margin: auto;
    background: #ffaa2a;
    border-radius: 8px;
    padding: 10px;
    justify-content: end;
    align-items: center;
}


.SaveBtnContainer {
    display: flex;
    align-items: center;
    justify-content: end;
}

.BottomBtn {
    background-color: #741715;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    display: inline-flex;
    /* Ensure it's a flex container to center text and icon */
    align-items: center;
    margin-right: 8px;
    /* Vertically centers text and icon */
}

.BottomBtn i{
    margin-top: 10px;
    margin-right: 10px;
}

.uploadInfoDiv {
    display: flex;
    flex-direction: column; /* Ensures the items are arranged in a column */

    background-color: #dddddd;
    border-radius: 8px;
    padding: 10px;
}

.uploadInfoItem {
    display: flex;
    align-items: center;
}

.uploadInfoItem label{
    transform: translateY(25%);
    width: 20%;
}

.DisplayInformation {
    position: relative;
}

.HideuploadInfoDiv {
    position: absolute;
    width: 320px;
    background-color: #dddddd;
    box-shadow: #000000 0px 0px 10px;
    border-radius: 8px;
    padding: 10px;
    top: 1.4em;
    right: 1.4em;
}

.HideuploadInfoDiv label{
    transform: translateY(25%);
    width: 30%;
}

.HideuploadInfoDiv input{
    width: 220px;
}

.PhotoBtn {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center; /* or adjust as needed */
    background-color: #741715;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    box-shadow: #000000 0px 0px 3px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    /* Ensure it's a flex container to center text and icon */
    right: 0em;
    top: 13.2em;
    /* Vertically centers text and icon */
}

.EditPhotoPanel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center; /* or adjust as needed */
    background-color: #741715;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    box-shadow: #000000 0px 0px 3px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    /* Ensure it's a flex container to center text and icon */
    right: 0em;
    top: 13.2em;
    /* Vertically centers text and icon */
}

.PhotoOptionPanel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center; /* or adjust as needed */
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    border: none;

    border-radius: 5px;
    box-shadow: #000000 0px 0px 3px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    /* Ensure it's a flex container to center text and icon */
    right: 0em;
    top: 15.6em;
    /* Vertically centers text and icon */
}

.UploadPhotoPanel ul {
    list-style-type: none;
    padding: 2px 5px;
    margin: 0;
    justify-content: start;
}

.UploadPhotoPanel li {
    border-radius: 8px;
    padding: 5px 12px;
    margin-bottom: 2px;
}

.UploadPhotoPanel li:hover {
 background-color: #fda500;
 color: white;
}

.UploadPhotoPanel input[type="file"] {
    position: absolute;
    height: 30px;
    width: 150px;
    filter: alpha(opacity=0);
    cursor: pointer;
    opacity: 0;
    background-color: #ef4b25;
}

.RemovePhotoBtn {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center; /* or adjust as needed */
    background-color: #741715;
    color: white;
    border: none;
    padding: 8px 8px;
    border-radius: 5px;
    box-shadow: #000000 0px 0px 3px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    /* Ensure it's a flex container to center text and icon */
    right: 0em;
    top: 0em;
    /* Vertically centers text and icon */
}

.RemovePhotoIcon {
    margin-top: 2px;
    margin-left: 2px;
    color: #ffffff;
    font-size: 22px;
}


.EditPhotoIcon {
    margin-top: 6px;
    margin-right: 10px;
    color: #ffffff;
}

.AddAllowanceBtn {
    background: var(--dash-secondary-button-background-color);
    color: var(--dash-secondary-button-text-color);
    padding: 8px 16px;
    border-radius: 5px;
    font-size: 16px;
    border: none;
    font-weight: 600;
}

.AddAllowanceBtn p{
    font-weight: 600;
}

.AddAllowanceIcon {
    margin-right: 10px; 
    font-weight: 600;
}

.UserAllowanceBtnContainer {
    width: 99%;
}

.DatePicker {
    background-color: #ffffff;
    width: 100%;
    height: 45px;
    font-size: 16px;
}

.errorBorder {
    border: 2px solid red; /* Subtle and visible */
    border-radius: 4px; /* Maintain input styling */
    color: red;
}
