.loadingSection {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;  
    align-items: center;
}

.loadingbardiv {
    position: relative; /* Make the div relative so that we can position the logo inside it */
    display: flex;
    justify-content: center;
    align-items: center;
}

.loadingBar {
    width: 120%;
    height: 120%;
}

.AClogo {
    position: absolute; /* Position the logo on top of the loading bar */
    width: 60%; /* Adjust size as needed */
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the logo within the container */
}