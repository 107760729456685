@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+HK:wght@100..900&family=Protest+Strike&family=ZCOOL+KuaiLe&family=ZCOOL+XiaoWei&display=swap');

*{
    font-family: "ZCOOL XiaoWei", sans-serif;
}

.sectionMain{
    margin: auto;
    width: 90%;
}

.ProductType {
    /* border: 2px black solid; */
    margin-bottom: 30px;
}

.ProductTypeName{
    padding-top: 10px;
    font-size: 35px;
    font-weight: bolder;
}

.ProductTypeDesc{
    font-size: 23px;
    padding-left: 5px;
}

.DisplayProduct{
    display: flex;
    flex-wrap: wrap;
}

.EachProduct{
    margin-top: 30px;
    margin-bottom: 20px;
    margin-left: 50px;
    border: 3px black solid;
    border-radius: 8px;
    width: 20%;
}

.ProductName {
    font-size: 30px;
    background-color: rgb(241, 230, 66);
    height: 70px;
    text-align: center;
}

.ProductName p{
    padding-top: 10px;
}

.ProductDescription{
    background-color: rgb(255, 255, 255);
    width: 95%;
    min-height: 270px;
    text-align: left;
    font-size: 22px;
}

.ProductDescription li{
    margin-left: 30px;
    margin-top: 15px;
}

.ProductOtherDESC {
    text-align: left;
    background-color: rgb(233, 233, 233);
    height: 100px;
}

.ProductOtherDESC p{
    padding-top: 10px;
    padding-left: 20px;
    text-align: left;
    font-size: 20px;
}

.discount {
    background-color: rgb(232, 240, 255);
    padding-left: 20px;
    height: 95px;
}

.discount p{
    padding-top: 10px;
    text-align: left;
    font-size: 20px;
}

.ContactDiv{
    margin: auto;
    width: 95%;
    justify-content: center;
    align-content: center;
    text-align: center;
}

.ProductBtn {
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: bold;
    border-radius: 10px;
    width: 100%;
    background-color: rgb(255, 174, 0);
    padding: 15px 16%;
}

.ContactDiv a{
    color: black;
    text-decoration: none;
    font-size: 25px;
}

.ContactDiv a:hover{
    color: black;
    text-decoration: underline;
    font-size: 25px;
}

@media screen and (max-width: 1300px) {

    .sectionMain{
        margin: auto;
        width: 98%;
    }

    .EachProduct{
        margin: auto;
        margin-top: 30px;
        margin-bottom: 20px;
        border: 3px black solid;
        border-radius: 8px;
        width: 30%;
    }
}

@media screen and (max-width: 760px) {

    .sectionMain{
        margin: auto;
        width: 98%;
    }

    .EachProduct{
        margin: auto;
        margin-top: 30px;
        margin-bottom: 20px;
        border: 3px black solid;
        border-radius: 8px;
        width: 80%;
    }
}