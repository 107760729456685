@import url('https://fonts.googleapis.com/css2?family=Chocolate+Classical+Sans&family=Jersey+25&family=Teko:wght@300..700&display=swap');

.MainSection {
    position: relative;
    /* 父容器需要設置 relative */
    margin: auto;
    background-color: var(--dash-page-background-color);
    border-radius: 8px;
    min-height: 75vh;
    width: 99%;
    min-width: 550px;
    padding: 8px;
}

.SelectionFilterDiv {
    margin-top: 20px;
    background-color: var(--dash-each-sub-div-background-color);
    border: var(--dash-each-sub-div-border-color) 2px solid;
    border-radius: 8px;
    min-height: 30vh;
}

.FilterTitleDiv {
    display: flex;
    background-color: var(--dash-each-sub-div-title-background-color);
    border-radius: 8px;
    width: 200px;
    height: 40px;
    transform: translate(20px, -20px);
    justify-content: space-between;
}

.FilterTitleDiv p {
    margin-left: 10px;
    font-weight: 600;
    color: var(--dash-each-sub-div-title-color);
    transform: translateY(6px);
}

.FilterTitleDiv i {
    margin-left: 10px;
    font-weight: 600;
    color: var(--dash-each-sub-div-title-color);
    transform: translate(-20px, 11px);
}

.MainFilterDiv {
    width: 100%;
    display: grid;
    grid-template-columns: 450px 1px 570px;
}

.ReportFilterDiv {
    grid-column: 1/1;
    padding: 15px;
}

.FilterLineDiv {
    grid-column: 2/2;
    background-color: rgb(0, 0, 0);
}

.ReportTypeDiv {
    grid-column: 1/1;
    padding: 15px;
}

.baseInfoItem {
    margin-bottom: 5px;
}

.baseInfoItem input[type="date"] {
    margin: 0;
    width: 98%;
    max-width: 300px;
    height: 25px;
    /* Set the same width as the textboxes */
    border: 1px solid #ccc;
    /* Border similar to textboxes */
    border-radius: 4px;
    /* Rounded corners */
    font-size: 16px;
    /* Same font size */
    background-color: #fff;
    /* White background for consistency */
    color: #000000;
}

.baseInfoItem input[type="text"] {
    margin: 0;
    width: 100%;
    max-width: 300px;
    /* Set the same width as the textboxes */
    border: 1px solid #ccc;
    /* Border similar to textboxes */
    border-radius: 4px;
    /* Rounded corners */
    font-size: 16px;
    /* Same font size */
    background-color: #fff;
    /* White background for consistency */
    color: #000000;
}

.baseInfoItem input[type="number"] {
    margin: 0;
    padding: 5px;
    height: 25px;
    width: 97%;
    max-width: 300px;
    /* Set the same width as the textboxes */
    border: 1px solid #ccc;
    /* Border similar to textboxes */
    border-radius: 4px;
    /* Rounded corners */
    font-size: 16px;
    /* Same font size */
    background-color: #fff;
    /* White background for consistency */
    color: #000000;
}

.baseInfoItem label {
    margin-left: 2px;
    margin-bottom: 2px;
    font-weight: 500;
    color: #000000;
}

.baseInfoItem select {
    padding: 3px;
    font-size: 18px;
    width: 100%;
    max-width: 315px;
    height: 38px;
    border-radius: 3px;
    margin-bottom: 5px;
}

.TypeFieldset {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    padding: 10px;
    border-radius: 10px;
    width: 480px;
}

.TypeFieldset legend {
    margin-left: 3px;
    padding: 10px;
    font-size: 18px;
    font-weight: 600;
    color: #000000;
}

.ProductTypeFieldset {
    display: flex;
    width: 100%;
    padding: 5px;
    color: #000000;
    font-weight: 600;
    transform: translateY(-10px);
}

.ProductTypeFieldset label {
    margin-left: 8px;
    margin-right: 8px;
}

.SelectionBackBtnDiv {
    position: absolute;
    /* 絕對定位 */
    bottom: 20px;
    left: 20px;
}

.SelectionBackBtn {
    background-color: var(--sub-button-background-color);
    color: #ffffff;
    padding: 12px 24px;
    font-size: 18px;
    font-weight: bold;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
}

/* Optional: Hover effect */
.SelectionBackBtn:hover {
    background-color: var(--sub-button-background-color-hover);
    /* Slightly lighter color on hover */
}

.SelectionBackBtn i {
    font-size: 18px;
    /* Adjust icon size */
    transform: translateY(3px);
}

.SelectionBtnDiv {
    position: absolute;
    /* 絕對定位 */
    bottom: 20px;
    right: 20px;
}

.SelectionBtn {
    background-color: var(--sub-button-background-color);
    color: #ffffff;
    padding: 12px 24px;
    font-size: 18px;
    font-weight: bold;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
}

/* Optional: Hover effect */
.SelectionBtn:hover {
    background-color: var(--sub-button-background-color-hover);
    /* Slightly lighter color on hover */
}

.SelectionBtn i {
    font-size: 18px;
    /* Adjust icon size */
}

.ShowFilterStatusDiv {
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
}

.EachFilterItem {
    margin-right: 5px;
    padding: 5px 10px;
    display: flex;
    background-color: var(--input-div-background-color);
    border: #9a9b9c 2px solid;
    border-radius: 8px;
}

.EachFilterItem select {
    border: none;
    font-size: 16px;
    font-weight: 600;
    margin-left: 3px;
    transform: translateY(-1px);
}

.EachFilterItem p {
    font-size: 16px;
    font-weight: 600;
    margin-left: 3px;
    transform: translateY(2px);
}

.OutputOption {
    margin-top: 30px;
    background-color: var(--input-div-background-color);
    border: #9a9b9c 2px solid;
    border-radius: 8px;

    display: grid;
    grid-template-columns: 1fr 1px 1.8fr;
}

.OptionLineDiv {
    margin-bottom: 15px;
    grid-column: 2/2;
    background-color: rgb(0, 0, 0);
}

.KeywordsDiv {
    grid-column: 3/3;
    padding-left: 15px;
}

.BrandFilterDiv {
    margin-top: 30px;
    background-color: var(--input-div-background-color);
    border: #9a9b9c 2px solid;
    border-radius: 8px;
    min-height: 30vh;
}

.EachFilterItem label {
    margin-right: 3px;
    transform: translateY(2px);
}

.EachFilterItem img {
    background-color: #ffffff;
    border: 1px solid #919191;
    border-radius: 5px;
    margin-right: 5px;
    width: 25px;
    height: 25px;
    object-fit: contain;
}

.BrandListDiv {
    display: flex;
    flex-wrap: wrap;
    padding-left: 20px;
    justify-content: start;
}

.EachBrandDiv {
    width: 145px;
    height: 80px;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 8px 10px;

    background-color: var(--input-div-background-color);
    border: #9a9b9c 2px solid;
    border-radius: 8px;
}

.EachBrandDiv img {
    object-fit: contain;
}

.ShopTableDiv {
    margin-top: 30px;
    background-color: var(--input-div-background-color);
    border: #9a9b9c 2px solid;
    border-radius: 8px;
    min-height: 5vh;
}

.ShopList {
    display: flex;
    justify-content: center;
}

.ShopTable {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 98%;
    margin-bottom: 20px;

}

.ShopTable td,
.ShopTable th {
    border: 1px solid #ddd;
    padding: 8px;
}

.ShopTable tr:nth-child(even) {
    background-color: #f2f2f2;
}

.ShopTable tr:hover {
    background-color: #ddd;
}

.ShopTable th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #ffffff;
    color: #000000;
}

.ControlBtnDiv {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.ShopTableDiv input[type="text"] {
    width: 93%;
    font-size: 18px;
    border-radius: 5px;
    text-align: left;
    margin-bottom: 10px;
    transform: translateX(14px);
}

.ShopListButtonDiv {
    display: flex;
    margin-bottom: 10px;
    margin-left: 15px;
}

.ShopListButtonDiv button {
    color: #ffffff;
    margin-right: 5px;
    padding: 5px 8px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transform: translateX(14px);
    transition: background-color 0.3s ease;
}

.CookiesKeywordsDiv {
    display: flex;
    flex-wrap: wrap;
}

.KeyWordsFieldset {
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
    width: 90%;

    border-radius: 8px;
    color: #000000;
    font-weight: 600;
    transform: translateY(-10px);
}

.KeyWordsFieldset legend {
    margin-left: 3px;
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
}

.KeyWordsFieldset label {
    margin-left: 8px;
    margin-right: 8px;
}

.KeywordInput {
    width: 0;
}

.AddKeywordButton {
    display: flex;
    background-color: #ffffff;
    padding: 6px 12px;
    border-radius: 8px;
    width: auto;
    margin-right: 10px;
    margin-top: 5px;
}

.AddKeywordButton p {
    font-weight: 600;
}

.AddKeywordButton i {
    font-weight: 600;
    font-size: 18px;
    margin-right: 5px;
    transform: translateY(3px);
}

.PackageNameDiv {
    display: flex;
    flex-wrap: wrap;
    width: 98%;
    align-content: center;
}

.PackageNameDiv input[type="text"] {
    width: 40%;
    height: 40px;
    padding: 6px 12px;
    margin-right: 10px;
}

.SavePackageButton {
    display: flex;
    align-content: center;
    background-color: #ffffff;
    padding: 8px 12px;
    border-radius: 8px;
    width: auto;
    margin-right: 10px;
    transform: translateY(2px);
}

.SavePackageButton p {
    font-weight: 600;
}

.SavePackageButton i {
    font-weight: 600;
    font-size: 18px;
    margin-right: 5px;
    transform: translateY(3px);
}

.KeywordPackageFieldset {
    width: 90%;
    padding: 5px;
    border-radius: 8px;
}

.KeywordPackageFieldset legend {
    margin-left: 3px;
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
}

.KeywordPackageFieldset button {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 5px 8px;
    margin-right: 10px;
    margin-bottom: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.KeywordPackageFieldset button p {
    font-weight: 600;
}

.KeywordPackageFieldset button:hover {
    background-color: #f0f0f0;
}

.P2MainDiv {
    display: grid;
    grid-template-columns: 1.6fr 1fr;
    grid-template-rows: 1fr;
}

.P2FilterDiv {
    padding: 15px;
    grid-column: 1/1;
    grid-row: 1/1;
}

.P2ShopListDiv {
    padding: 15px;
    grid-column: 2/2;
    grid-row: 1/1;
}

.ShopList2 {
    display: flex;
    justify-content: center;
}

.ShopTable2 {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 98%;
    max-height: 80vh;
    overflow: hidden;
    margin-bottom: 20px;

}

.ShopTable2 td,
.ShopTable2 th {
    border: 1px solid #ddd;
    padding: 8px;
}

.ShopTable2 tr:nth-child(even) {
    background-color: #f2f2f2;
}

.ShopTable2 tr:hover {
    background-color: #ddd;
}

.ShopTable2 th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #ffffff;
    color: #000000;
}

.ShopTableContainer {
    height: 51.5vh;
    /* Set the maximum height */
    overflow-y: auto;
    /* Enable vertical scrolling */
    width: 98%;
    /* Ensure the width fits within the layout */

}

.PackageNameDiv2 {
    display: flex;
    flex-wrap: wrap;
    width: 99%;
    align-content: center;
    justify-content: left;
}

.PackageNameDiv2 input[type="text"] {
    width: 60%;
    height: 40px;
    padding: 6px 12px;
    margin-right: 10px;
}

.SavePackageButton2 {
    display: flex;
    align-content: center;
    background-color: #ffffff;
    padding: 6px 10px;
    border-radius: 8px;
    margin-right: 10px;
    transform: translateY(2px);
}

.SavePackageButton2 p {
    font-size: 13px;
    font-weight: 600;
}

.SavePackageButton2 i {
    font-weight: 600;
    font-size: 22px;
    margin-right: 3px;
    transform: translateY(3px);
}

.EachBrandDiv2 {
    width: 100px;
    height: 60px;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 8px 10px;

    background-color: var(--input-div-background-color);
    border: #9a9b9c 2px solid;
    border-radius: 8px;
}

.EachBrandDiv2 img {
    object-fit: contain;
}

.baseInfoItem2 {
    margin-bottom: 5px;
}

.baseInfoItem2 input[type="text"] {
    margin: 0;
    width: 80%;

    /* Set the same width as the textboxes */
    border: 1px solid #ccc;
    /* Border similar to textboxes */
    border-radius: 4px;
    /* Rounded corners */
    font-size: 16px;
    /* Same font size */
    background-color: #fff;
    /* White background for consistency */
    color: #000000;
}

.ExtensionKeyInputDiv2 {
    background-color: var(--input-div-background-color);
    width: 87%;
    padding: 10px;
    border: #9a9b9c 2px solid;
    border-radius: 8px;
    margin-bottom: 8px;
}

.ExtensionDiv {
    padding-top: 18px;
}

.SeachShopText2 {}

.D3_P2MainDiv {
    display: grid;
    min-height: 75vh;
    grid-template-columns: 470px 580px 590px;
    grid-template-rows: 1fr;
}

.D3_ReportDiv {
    padding: 15px;
    grid-column: 3/3;
    grid-row: 1/1;
}

.D3_OutputOption {
    margin-top: 30px;
    background-color: var(--input-div-background-color);
    border: #9a9b9c 2px solid;
    border-radius: 8px;
    height: 67vh;
}

.D3_grapTable {
    display: grid;
    grid-template-rows: 1fr 1px 1.8fr 0.3f;
}

.D3_KeyWordControlBtn {
    display: flex;
    padding: 15px;
    justify-content: end;
    align-content: end;
}

.AddFilterBtn {
    margin-right: 20px;
    background-color: var(--sub-button-background-color);
    color: #ffffff;
    padding: 8px 12px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
}

.D3_ReportTypeDiv {
    grid-row: 3/3;
    padding: 15px;
}

.D3_OptionLineDiv {
    margin-bottom: 15px;
    grid-row: 2/2;
    width: 95%;
    min-height: 1px;
    margin: auto;
    background-color: rgb(0, 0, 0);
}

.D3_KeywordsDiv {
    grid-row: 1/1;
    padding-left: 15px;
}

.D3_P2ShopListDiv {
    padding: 15px;
    grid-column: 2/2;
    grid-row: 1/1;
}

.D3_P2FilterDiv {
    padding: 15px;
    grid-column: 1/1;
    grid-row: 1/1;
}

.D3_BrandFilterDiv {
    margin-top: 30px;
    background-color: var(--input-div-background-color);
    border: #9a9b9c 2px solid;
    border-radius: 8px;
    min-height: 67vh;
}

.D3_KeywordPackageFieldset {
    width: 90%;
    padding: 5px;
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.D3_KeywordPackageFieldset p{
    border: 1px #9a9b9c solid;
    border-radius: 8px;
    padding: 3px 8px;
    margin-left: 3px;
    margin-bottom: 3px;
}

.ShopListButtonDiv label {
    margin-left: 10px;
}

.SWOCDiv {
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
}

.SWOClable {
    margin-left: 10px;
    font-size: 16px;
}

.SWOCDiv input[type="checkbox"] {
    transform: translateX(4px);
}