.darkpannel {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: center;
}

.MainDiv {
    position: absolute;
    width: 650px;
    height: 300px;
    background-color: #e5e5e5;
    border-radius: 8px;
    border: 2px #767676 solid;
}

.MessageBoxHeader {
    position: relative;
    display: flex;
    width: 100%;
    height: 45px;
    background-color: #ffc146;
    border-radius: 8px 8px 0px 0px;
    align-items: center;
}

.MessageBoxHeader h3{
    margin-left: 5px;
    letter-spacing: 1.2px;
    color:#000000
}

.MessageBoxContent{
    position: relative;
    display: flex;
    width: 100%;
    height: 200px;
    background-color: #e5e5e5;
    border-radius: 0px 0px 8px 8px;
    align-items: center;
    justify-content: center;
}

.MessageBoxFooter{
    position: relative;
    display: flex;
    width: 100%;
    height: 55px;
    background-color: #e5e5e5;
    border-radius: 0px 0px 8px 8px;
    align-items: center;
    justify-content: center;
}


.ConfirmBtn {
    margin: 8px;
    width: 100px;
    height: 35px;
    border: none;
    border-radius: 5px;
    background-color: #54c977;

    color: rgb(19, 19, 19);
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1.2px;
}

.RemovemBtn {
    margin: 8px;
    width: 100px;
    height: 35px;
    border: none;
    border-radius: 5px;
    background-color: #ff6767;

    color: rgb(19, 19, 19);
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1.2px;
}


