.MainSection {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    padding: 15px;
}

.EachTypeItem {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.EachTypeItem a{
    margin: 0;
    padding: 0;
    text-decoration: none;
    font-size: 18px;
    display: flex;
    align-content: center;
    color: #262626;
}

.iconStyles {
    font-size: 22px;
    margin-right: 15px;
    margin-top: 8px;
}

.EachTypeItem p{
    margin-top: 4px;
    font-weight: 500;
    color: #262626;
}
