.MainSection{
    margin: auto;
    width: 90%;
}

.Title{
    margin-bottom: 20px;
}

.DisplayFT {
    margin: auto;

    padding: 30px;
    border-radius: 12px;
    display: flex;
    flex-wrap: wrap;
}
.EachFTDiv {
    display: flex;
    background-color: rgb(255, 255, 255);
    margin: 5px;
    border-radius: 15px;
    border: 2px black solid;
    width: 340px;
    height: 180px;
    float: left;
    align-items: center;
}

.EachFunctionDesc {
    border: 2px red solid;
    justify-content: center;

}

.FunctionTitle {
    border: 2px red solid;
    font-size: 20px;
    font-weight: 600;
}

.FunctionBtn {
    display: flex;
    height: 30px;

}

.FunctionBtn p{
    font-size: 20px;
}

.ClickBtnDiv {
    border: 2px rebeccapurple solid;
    padding: 5px 10px;
}


.ClickMeDiv a{
    color: black;
    text-decoration: none;
}

.EachFunctionImgDiv {
    align-items: center;
    padding: 15px;
    width: 130px;
    height: 130px;
}

.FunctionImg {
    text-align:center;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.FunctionImg img {
    height: 60%;
    width: 60%;
}

.TitleName{
    background-color: rgb(241, 241, 241);
    text-align: center;
    font-size: 25px;
    padding-top: 10px;
    height: 40px;
}

.Description {
    height: 130px;
    margin-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
}

.Description p{
    font-size: 18px;
    word-wrap: break-word;
}

.DescMesage{
    margin-top: 10px;
}

.FTBtn {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 10px;
}

.FTBtn button{
    background-color: rgba(233, 233, 233, 0.226);
    font-size: 23px;
    border-radius: 5px;
    width: 95%;
    height: 50px;
    transition: background-color 0.8s;
}

.FTBtn a{
    text-decoration: none;
    color: black;
    background-color: rgba(233, 233, 233, 0.226);
    font-size: 23px;
    border-radius: 15px;
    width: 95%;
}

.FTBtn button:hover{
    font-weight: bold;
    background-color: rgb(255, 196, 0);
    color: black;
}

.Title {
    margin-left: 50px;
    margin-top: 30px;
}

.Title p{
    font-size: 32px;
}

@media screen and (max-width: 1100px){

    .MainSection{
        margin: auto;
    }

    .EachFTDiv {
        margin-left: 20px;
        margin-bottom: 20px;
        border-radius: 15px;
        border: 3px black solid;
        float: left;
    }
    
    
}