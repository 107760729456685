@keyframes slideUp {
    0% {
        transform: translateY(200%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.AlertMessageSection {
    position: fixed;
    top: 0;
    left: -3em;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: end;
    pointer-events: none; /* Allow interactions with elements behind */
    z-index: 9999;
    animation: slideUp 1.5s ease-out; /* Add slide-down animation */
}

.SuccessAlertMessageDiv {
    display: flex;
    min-width:30%;
    padding:20px;
    position:relative;
    border-radius:5px;
    background-color: #deffcf;
    border: #cbf5b8 2px solid;
    box-shadow:0 0 15px 5px #ccc;
    align-content: center;
    align-items: center;
}

.SuccessAlertMessageDiv p {
    color: #064d00;
}

.SuccessAlertMessageDiv h3 {
    color: #064d00;
    margin-right: 10px;
}

.SuccessAlertMessageDiv i {
    color: #064d00;
    margin-right: 10px;
    transform: translateY(0.2em);
}

/* Error */
.ErrorAlertMessageDiv {
    display: flex;
    min-width:30%;
    padding:20px;
    position:relative;
    border-radius:5px;
    background-color: #ffcfcf;
    border: #f5b8b8 2px solid;
    box-shadow:0 0 15px 5px #ccc;
    align-content: center;
    align-items: center;
}

.ErrorAlertMessageDiv p {
    color: #4d0000;
}

.ErrorAlertMessageDiv h3 {
    color: #4d0000;
    margin-right: 10px;
}

.ErrorAlertMessageDiv i {
    color: #4d0000;
    margin-right: 10px;
    transform: translateY(0.2em);
}

/* Warning */
.WarningAlertMessageDiv {
    display: flex;
    min-width:30%;
    padding:20px;
    position:relative;
    border-radius:5px;
    background-color: #fffecf;
    border: #f5f4b8 2px solid;
    box-shadow:0 0 15px 5px #ccc;
    align-content: center;
    align-items: center;
}

.WarningAlertMessageDiv p {
    color: #4c4d00;
}

.WarningAlertMessageDiv h3 {
    color: #4c4d00;
    margin-right: 10px;
}

.WarningAlertMessageDiv i {
    color: #4c4d00;
    margin-right: 10px;
    transform: translateY(0.2em);
}

/* Info */
.InfoAlertMessageDiv {
    display: flex;
    min-width:30%;
    padding:20px;
    position:relative;
    border-radius:5px;
    background-color: #f8f8f8;
    border: #e4e4e4 2px solid;
    box-shadow:0 0 15px 5px #ccc;
    align-content: center;
    align-items: center;
}

.InfoAlertMessageDiv p {
    color: #535353;
}

.InfoAlertMessageDiv h3 {
    color: #535353;
    margin-right: 10px;
}

.InfoAlertMessageDiv i {
    color: #535353;
    margin-right: 10px;
    transform: translateY(0.2em);
}