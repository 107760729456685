.MainSection {
    width: 100%;
    height: 55px;
}

.ControlBarDiv {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
}

.Notifications {
    margin-top: 8px;
    margin-right: 12px;
}

.BellIcon {
    font-size: 25px;
    color: #01134e;
}

.UserIcon {
    width: 45px;
    height: 45px;

    border-radius: 100px;
    border: 3px solid #ffffff;
    overflow: hidden;
    border: 3px #001c75 solid;
}

.UserIcon img {
    object-fit: cover;
}

.MenuDiv {
    margin-top: 8px;
    margin-right: 10px;
    font-size: 25px;
    color: #161616;
}

.UserMenu {
    position: absolute;
    width: 150px;
    border: 2px #161616 solid;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 10px;
    top: 2.2em;
    right: 0.5em;
    z-index: 998;
}

.eachMenuItem {
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    cursor: pointer;
}

.eachMenuItem i{
    font-size: 20px;
    color: #161616;
    transform: translateY(5px);
}

.UserMenu ul {
    list-style-type: none;
    padding: 0;
    font-size: 18px;
}

.eachMenuItem:hover, .eachMenuItem:hover i {
    color: #0635ce;

}

.NotificationDropdown {
    position: absolute;
    width: 260px;
    max-height: 800px;
    border: 2px #161616 solid;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 10px;
    top: 3.5em;
    right: 5.5em;
    z-index: 998;
    overflow: hidden;
    overflow-y: auto;
}

.NotificationDropdown ul {
    list-style-type: none;
    padding: 0;
    font-size: 18px;
}

.eachNotification {
    display: flex;
}

.MessageIconDiv {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #181818;
    border-radius: 100px;
    font-size: 25px;
    margin-bottom: 10px;
    margin-right: 15px;
}

.MessageIcon{
    transform: translateY(3px);
    color: #ffe600;
    text-shadow: 
        1px 1px 0 #fff, /* Top-left shadow */
        -1px -1px 0 #fff, /* Bottom-right shadow */
        -1px 1px 0 #fff, /* Top-right shadow */
        1px -1px 0 #fff; /* Bottom-left shadow */
}

.MessageData {
    width: 200px;
    display: flex;
    flex-direction: column;
}

.mainMessage {
    font-size: 18px;
}

.MessageDate {
    font-size: 14px;
    color: #7a7a7a;
}

.NoAlretMessage {
    font-size: 18px;
    color: #414040;

}