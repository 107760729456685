.DashLayout {
    display: grid;
    grid-template-rows: 65px 1fr;
}

.navbar {
    grid-row: 1 / 1;
    background-color: #303030;
}

.Outlet {
    grid-row: 2 / 2;
    background-color: #f5f5f5;

    border-radius: 5px;
    width: 100%;
    min-height: 92vh;
    overflow-y: auto;
}