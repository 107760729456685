.MainSection {
    background-color: var(--dash-page-background-color);
    min-height: 89.8vh;
}

.ProductuctSalesBoard {
    max-width: 95%;
    max-height: 45vh;
    padding: 20px;
    border: 2px #9f9f9f solid;
    border-radius: 8px;
}

.ProductuctSalesDiv {
    max-width: 100%;
    overflow-x: auto;
}

.barChart {
    width: 200%;
}

.ChartTitle {
    font-size: 23px;
    font-weight: 600;
    margin-bottom: 12px;
}