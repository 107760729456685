@import url('https://fonts.googleapis.com/css2?family=Bungee&display=swap');

.MainSection {
    width: 98.7%;
    min-height: 87.8vh;
    background-color: var(--dash-page-background-color);
    padding: 10px;
}

.TopDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
}

.PageTitle {
    font-family: "Bungee", sans-serif;
    margin: 0;
    padding: 10px;
    font-size: 26px;
    font-weight: 600;
    color: var(--dash-page-title-text-color);
}

.dividingline{
    width: 100%;
    height: 1px;
    background-color: var(--dash-dividingline-color);
    margin-top: 10px;
    margin-bottom: 10px;
}

.Functiondividingline {
    width: 100%;
    height: 1px;
    background-color: #000000;
    margin-top: 10px;
    margin-bottom: 10px;
    transform: translateY(10px);
}

.FunctionList {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    padding: 15px;
}

.FunctionItem {
    margin-right: 25px;
    margin-bottom: 35px;
    width: 200px;
    height: 210px;
    background-color: #ffffff;
    border-radius: 20px;
    padding: 20px;
    box-shadow:  0 0 8px #202020;
}

.FunctionIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    transform: translateY(-45px);
    border-radius: 100%;
}

.FunctionIcon i{
    color: #ffffff;
    font-size: 20px;
    line-height: 1;
}

.FunctionName {
    font-size: 22px;
    font-weight: 600;
    color: #741715;
    transform: translateY(-30px);
}

.FunctionControlDiv {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

}

.FunctionDesc { 
    align-content: center;
    height: 55px;
    font-size: 16px;
}

.LinkDiv {
    width: 50%;
}

.FunctionControlDiv a{
    margin: 0px;
    padding: 0px;
}

.FunctionBtn{
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 30px;
    background-color: var(--dash-primary-button-background-color);
    border-radius: 8px;
    color: #ffffff;
    border: none;
    cursor: pointer;
}

.FunctionStatusDiv {
    margin-right: 15px;
    text-align: center;
}

.functionStatus {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #741715;
}

.FunctionBtn:hover {
    background-color: var(--dash-primary-button-background-color-hover);
    color: #ffffff;
}

.SelectionDiv {
    padding: 15px;
}

.TypeBtn {
    margin-right: 10px;
    border-radius:  8px;
    background-color: var(--dash-secondary-button-background-color);
    color: var(--dash-secondary-button-text-color);
    padding: 7px 10px;
    font-size: 16px;
    font-weight: 600;
    box-shadow: #887e7e 0px 0px 5px ;
}

.TypeBtn:hover {
    border-radius:  8px;
    background-color: var(--dash-secondary-button-background-color-hover);
    color: var(--dash-secondary-button-text-color);
}