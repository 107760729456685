.MainSection{
    background-color: #ffffff;
    width: 100%;
    border-radius: 8px;
    min-height: 86vh;
}

.TopDiv {
    width: 99%;
    padding: 10px;
}

.ReturnMenuBtn {
    width: 170px;
    padding: 10px 12px;
    display: flex;
    background-color: var(--dash-primary-button-background-color);
    border-radius: 8px;
    justify-content: center;
    align-content: center;
    align-items: center;
    cursor: pointer;
}

.ReturnMenuBtn p{
    color: var(--dash-primary-button-text-color);
    font-size: 22px;
    font-weight: 600;
    margin-left: 8%;
}

.ReturnIcon {
    color: var(--dash-primary-button-text-color);
    font-size: 22px;
    transform: translateY(3px);
}

.ReturnMenuBtn:hover {
    background-color: var(--dash-primary-button-background-color-hover);
}

.filterDiv {
    padding: 10px;
    width: 99%;
}

.filterDiv h3 {
    font-size: 23px;
    margin-left: 8px;
    margin-bottom: 5px;
}

.searchDiv {
    width: 98%;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    border-radius: 8px;
    border: 2px solid var(--dash-each-sub-div-border-color);
    padding: 10px;
}

.EachFilterItemDiv {
    width: 19%;
    margin-bottom: 10px;
    border: 2px red ;
}

.EachFilterItemDiv label{
    font-size: 18px;
    font-weight: bold;
}

.DatePicker {
    background-color: #ffffff;
    width: 80%;
    height: 45px;
    font-size: 16px;
}

.EachFilterItemDiv select{
    background-color: #ffffff;
    width: 80%;
    height: 45px;
    font-size: 16px;
    border-radius: 5px;
}

.EachFilterItemDiv input{
    background-color: #ffffff;
    width: 80%;
    height: 45px;
    font-size: 16px;
    border-radius: 5px;

}