@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+HK:wght@500&display=swap');

.MainSection{
    width: 100%;
}

.Img{
    width: 100%;
    height: 600px;
}

.banner{
    font-family: "Noto Sans HK", sans-serif;
    text-align: center;
    margin-top: 15px;
}

.SloganTitle {
    margin: 0;
    margin-top: 200px;
    color: rgb(255, 255, 255);
    text-shadow: rgb(0, 0, 0) 5px 0 10px;
    font-style: italic;
    font-weight: bold;
    font-size: 50px;
}

.SubSloganTitle {
    margin: 0;
    margin-bottom: 10px;
    color: rgb(255, 255, 255);
    text-shadow: rgb(0, 0, 0) 5px 0 10px;
    font-style: italic;
    font-size: 45px;
}

.JoinUs1 {
    margin-top: 8px;
    font-family: "Noto Sans HK", sans-serif;
    font-size: 18px;
    background: #ffbb00;
    padding: 8px 15px;
    text-align:  center;
    text-decoration: none;
    color: #000000;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: box-shadow, transform;
    transition-property: box-shadow, transform;
}
.JoinUs1:hover, .btn:focus, .btn:active{
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.JoinUs2 {
    margin-top: 8px;
    margin-left: 10px;
    font-family: "Noto Sans HK", sans-serif;
    font-size: 18px;
    background: #252525;
    padding: 8px 15px;
    text-align:  center;
    text-decoration: none;
    color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: box-shadow, transform;
    transition-property: box-shadow, transform;
}
.JoinUs2:hover, .btn:focus, .btn:active{
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}