@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Outfit:wght@100..900&display=swap');

* {
    padding: 0;
    margin: 0;
    font-family: "Outfit", sans-serif;
}

.TopSection {
    margin: auto;
    width: 95%;
    background-color: white;
    border: 12px;
    position: relative;
}

.ControlBar {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
}

.Title {
    font-size: 30px;
    font-weight: 600;
}

.RefreshBtn {
    margin-top: 10px;
    margin-right: 20px;
    position: absolute;
    right: 0;
    font-size: 30px;
    border-radius: 5px;
    text-align: center;
}


.ListUser{
    margin-top: 20px;
}

.UserTable {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.UserTable td,
.UserTable th {
    border: 1px solid #ddd;
    padding: 8px;
}

.UserTable tr:nth-child(even) {
    background-color: #f2f2f2;
}

.UserTable tr:hover {
    background-color: #ddd;
}

.UserTable th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #04AA6D;
    color: white;
}

.EditUserBtn {
    text-align: center;
}

.EditUserBtn:hover {
    cursor: pointer;
}