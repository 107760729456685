* {
    margin: 0;
    padding: 0;
}

.MainSection {
    background-color: var(--background-color);
    border-radius: 8px;
    min-height: 85vh;
    padding: 8px;
}

.TopDiv a {
    margin: 0px;
    text-decoration: none;
    color: #ffffff;
}

.ReturnToMenuBtn {
    margin-top: 8px;
    display: flex;
    width: 140px;
    padding: 8px 3px;
    background-color: #024a83;
    cursor: pointer;
    margin-left: 10px;
    align-content: center;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-size: 22px;
}

.ReturnMenuIcon {
    display: flex;
    font-size: 35px;
    margin-right: 5px;
    align-self: center;
}

.FunctioListDiv {
    position: relative;
    width: 98%;
    min-height: 70vh;
    margin-top: 40px;
    border: 2px #9a9b9c solid;
    box-shadow: 0px 0px 5px 0px #9a9b9c;
    border-radius: 8px;
}

.FunctionListTitle {
    position: relative;
    display: flex;
    width: 160px;
    background-color: #016fc5;
    border-radius: 5px;
    padding: 8px 5px;
    justify-content: center;
    align-content: center;
    transform: translate(40px, -26px);
}

.FunctionListTitle p {
    color: #ffffff;
    font-size: 22px;
    font-weight: 600;
}

.MainFunctionDisplayDiv {
    display: flex;
    flex-wrap: wrap;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: flex-start;
}

.MainFunctionDisplayDiv a{
    margin: 0;
    text-decoration: none;
}

.eachFunctionDiv {
    margin: 20px;
    margin-top: 5px;
    width: 320px;
    height: 239px;
    border: 2px #9a9b9c solid;
    border-radius: 8px;
    background-color: #eeeeee;
    box-shadow: 0px 0px 5px 0px #9a9b9c;
}

.FunctionTitle {
    width: 100%;
}

.FunctionTitle p{
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    padding: 5px;
}

.FunctionMainContent {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.FunctionImage{
    background-color: #ffffff;
    padding: 10px;
    border-radius: 8px;
    border: 2px #9a9b9c solid;
    width: 35%;
}

.FunctionDesc {
    display: flex;
    width: 65%;
    padding: 10px;
}

.FunctionDesc p{
    font-size: 16px;
    font-weight: 500;
}

.FunctionButtonDiv {
    width: 90%;
    display: flex;
    justify-content: center;
    padding: 10px;
}

.FunctionButton {
    width: 100%;
    padding: 5px;
    background: #016fc5;
    color: #ffffff;
    border: none;
    font-weight: 600;
    font-size: 26px;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0px 0px 5px 0px #9a9b9c;
}











