.MainSection {
    width: 100%;
    margin: auto;
    margin-bottom: 50px;
}

.TitleDiv {
    text-align: center;
    font-size: 1.6em;
    font-weight: 600;
    color: #131e65;
}

.TitleDiv p {
    margin-top: 20px;
    margin-bottom: 20px;
}

.parentContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.RegulationsTitle {
    display: inline-block;
    margin: auto;
    margin-top: 30px;
    border: 0.1em black solid;
    border-radius: 0.6em;
    font-weight: 800;
    font-size: 1.8em;
    padding: 0.3em 0.6em;
    text-align: center;
}

.RegulationsTitle2 {
    width: 80%;
    margin: auto;
    margin-top: 30px;
    border: 2px black solid;
    border-radius: 12px;
    font-weight: 800;
    font-size: 1.8em;
    padding: 5px 10px;
    text-align: center;
}

.LinkAndFileDiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.PDFimgDiv {
    margin-top: 30px;
    margin-right: 30px;
    width: 150px;
    height: 150px;
}

.Link1Div {
    display: flex;
    font-size: 1.5em;
    margin-top: 80px;
    font-weight: 600;
}

.Link3Div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 30px;
}

.eachLinkDiv {
    display: flex;
    font-size: 1.3em;
    margin-top: 80px;
    margin-left: 40px;
    margin-right: 40px;
    font-weight: 600;
}

.eachLinkDiv a {
    color: #d81213;
    text-decoration: none;
}

.Link1Div a {
    color: #d81213;
    text-decoration: underline;
}

.Link3Div a {
    text-decoration: underline;
}

.videoDiv {
    width: 640px;
    height: 360px;
    margin: auto;
    margin-top: 30px;
}

.result {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.EachResult {
    margin: 50px;
    font-size: 1.5em;
    color: #131e65;
    font-weight: 600;
}

.EachResult a {
    color: red;
    text-decoration: underline;
}

.ReasultTitle {
    border: 2px black solid;
    margin: auto;
    text-align: center;
    border-radius: 12px;
    margin-bottom: 20px;
}

.EachLink {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
}

.EachLink a,
p {
    font-size: 1.2em;
}


.Line {
    height: 1.2em;
    background-color: #b20000;
}



.Regulations2SubTitle {
    margin-top: 30px;
    color: #513c37;
    border: 2px #1d1d1f solid;
    text-align: center;
    padding: 10px 30px;
    border-radius: 8px;
}

.Regulations2SubTitle p {
    font-weight: 600;
    font-size: 1.6em;
}

.Regulations2Group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.EachRegulations2 {
    margin-left: 30px;
    margin-right: 30px;
}

.EachLink2 {
    display: flex;
    color: #131e65;
    font-size: 1.6em;
    font-weight: 600;
    margin-top: 10px;
}

.EachLink2 a {
    color: #d81213;
    text-decoration: underline;
}

.EachLink2 a,
p {
    font-size: 1.3em;
}

@media screen and (max-width: 1300px) {
    .MainSection {
        width: 100%;
        margin: auto;
    }

    .TitleDiv {
        text-align: center;
        font-size: 1.3em;
        font-weight: 600;
        color: #131e65;
    }

    .TitleDiv p {
        margin-top: 20px;
    }

    .RegulationsTitle {
        width: 75%;
        margin: auto;
        margin-top: 30px;
        margin-right: 20px;
        border: 2px black solid;
        border-radius: 12px;
        font-weight: 800;
        font-size: 26px;
        padding: 5px 10px;
        text-align: center;
    }

    .Link1Div {
        display: flex;
        font-size: 22px;
        margin-top: 80px;
        font-weight: 600;
    }

    .eachLinkDiv {
        display: flex;
        font-size: 1.1em;
        margin-top: 80px;
        margin-left: 40px;
        margin-right: 40px;
        font-weight: 600;
    }

    .videoDiv {
        width: 82%;
        margin: auto;
        margin-top: 30px;
    }

    .video {
        width: 100%;
    }

    .ReasultTitle {
        font-size: 1.5em;
    }

    .EachResult {
        width: 100%;
        font-size: 1.1em;
        color: #131e65;
        font-weight: 600;
    }

    .EachLink a,
    p {
        font-size: 1em;
    }

    .EachLink2 {
        display: flex;
        color: #131e65;
        font-size: 1em;
        font-weight: 600;
        margin-top: 10px;
    }

    .Regulations2SubTitle {
        margin-left: 20px;
    }
}