@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Outfit:wght@100..900&display=swap');

* {
    padding: 0;
    margin: 0;
    font-family: "Outfit", sans-serif;
}

.BaseInfoSection{
    margin: auto;
    margin-top: 30px;
    width: 90%;
    border: 2px rgb(146, 142, 142) solid;
    background-color: rgb(246, 247, 252);
    border-radius: 10px;
}

.subTitleDiv {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 8vh
}

.BaseInfoSection i {
    padding-left: 10px;
    padding-top: 20px;
    font-size: 35px;
}

.subTitle {
    padding-left: 5px;
    padding-top: 20px;
    font-size: 35px;
    font-weight: bolder;
}

.BaseInfoDiv{
    display: flex;
    flex-wrap: wrap;
}

.EachFormDiv {
    padding-top: 20px;
    padding-left: 20px;
    font-size: 25px;
}

.EachFormDiv p{
    font-family: "Bebas Neue", sans-serif;
}

.EachFormDiv100 p{
    font-family: "Bebas Neue", sans-serif;
}

.EachFormDiv100 {
    padding-top: 20px;
    padding-left: 20px;
    font-size: 25px;
    width: 100%;
}

.LoginIDText {
    margin-left: -2px;
    padding-left: 5px;
    font-size: 20px;
    border: 3px black solid;
    border-radius: 5px;
    width: 300px;
}

.LoginIDText:focus {
    background-color: rgb(244, 247, 255);;
}

.StatusRadio {
    margin-left: 20px;
    padding-left: 5px;
    font-size: 20px;
    border: 3px black solid;
    border-radius: 5px;
}

.RadioDiv {
    margin-left: -20px;
}

.RemarkText {
    margin-left: -2px;
    padding-left: 5px;
    font-size: 20px;
    border: 3px black solid;
    border-radius: 5px;
    width: 90%;
}

.RemarkText:focus {
    background-color: rgb(244, 247, 255);;
}


