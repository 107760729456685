@import url('https://fonts.googleapis.com/css2?family=Chocolate+Classical+Sans&family=Jersey+25&family=Teko:wght@300..700&display=swap');

.MainSection {
    display: flex;
    background-color: #f5f5f5;
}

.SelectCompanyDiv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 auto;
    width: 80%;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.EachCompanyDiv {
    margin: 20px;
    width: 300px;
    border-radius: 8px;
    border: 2px solid #636262;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.EachCompanyDiv a {
    color: #000000;
    text-decoration: none;
    text-align: center;
}

.CompanyACIDDiv {
    width: 80%;
    margin: auto;
    background-color: #f89d36;
    border-radius: 8px;
    border: 2px solid #636262;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    transform: translateY(-22px);
    justify-content: center;
    align-items: center;
    align-self: center;
}

.CompanyACIDDiv p {
    font-family: 'Teko', sans-serif;
    font-size: 24px;
    color: #fff;
    text-align: center;
    letter-spacing: 3px;
    font-weight: 600;
    padding: 10px;
}

.CompanyLogoDiv {
    height: 200px;
    padding: 10px;
    overflow: hidden;
}

.CompanyLogoDiv img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.CompanyNameDiv {
    display: flex;
    height: 53px;
    background-color: #d8ffd9;
    padding: 10px;
    justify-content: center;
    align-items: center;
}

.CompanyNameDiv p {
    font-weight: 300;
    text-shadow: #000000 1px 0 1px;
    text-align: center;
}

.CompanyButtonDiv {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    width: 80%;
    padding: 15px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    justify-content: center;
    align-items: center;;
    transition: background-color 0.3s;
}

.CompanyButtonDiv:hover {
    background-color: #f5f5f5;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}