.SelectPart {
  width: 95%;
}

.RaceRank{
  min-height: 80vh;
}

.BaseInfoDiv {
  margin: 10px;
}

.TitleMessage{
  margin-left: 20px;
  background-color: #ddd;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 5px 30px;
  text-align: center;
  border: 2px black solid;
  font-size: 23px;
  font-weight: bold;
}

.BaseInfoDiv p{
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 5px 30px;
  text-align: center;
  border: 2px black solid;
  font-size: 23px;
  font-weight: bold;
}

.SelectPart p{
  margin-left: 10px;
  padding: 5px 8px;
  display: flex;
  font-size: 20px;
  font-weight: bold;
}

.RaceRank input {
  display: flex;
  margin-left: 10px;
  margin-top: 10px;
  padding: 5px 8px;
  font-size: 20px;
  border-radius: 8px;
}

.SelectRaceNo {
  width: 100%;
  min-height: 50px;
  margin-left: 10px;
  margin-top: 10px;
}

.SelectRaceNoBtn {
  float: left;
  width: 50px;
  height: 50px;
}

.SelectRaceNoBtn button {
  text-align: center;
  font-size: 25px;
  padding: 10px 20px;

}

.Basetable{
  border-collapse: collapse;
  width: 30%;

}

.Basetable td, .Basetable th {
  border: 1px solid #ddd;
  padding: 8px;
}

.Basetable tr:nth-child(even){background-color: #f2f2f2;}
.Basetable tr:hover {background-color: #ddd;}

.RaceTable {
  border-collapse: collapse;
}

.RaceTable td, .RaceTable th {
  border: 1px solid #ddd;
  padding: 8px;
}

.RaceTable tr:nth-child(even){background-color: #f2f2f2;}
.RaceTable tr:hover {background-color: #ddd;}

.rider_id{
  width: 30px;
}

@media screen and (max-width: 760px) {
  .SelectPart {
    height: 30vh;
  }

}