.MainSection{
    margin: auto;
    width: 100%;
}

.footagMain{
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 30px;
    background-color: rgb(240, 240, 240);
}

.DescriptionMessage{
    display: flex;
}

.footagContent {
    margin-left: 30px;
    width: 20%;
}

.footagContent p{
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: left;
    font-size: 20px;
}

.footagTitle {
    font-size: 20px;
}

.footagMessage {
    font-size: 16px;
}

.Copyright {
    clear:both;
    text-align: center;
}

.Copyright p{
    margin-top: 15px;
    font-size: 12px;
    text-align: center;
    line-height: 30px;
}

.Copyright a{
    color: black;
    text-decoration: none;
}

@media screen and (max-width: 760px){

    .DescriptionMessage{
        flex-direction: column;
    }

    .footagContent {
        margin-left: 30px;
        width: 50%;
    }
    
    
}