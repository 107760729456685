.TopSection {
    margin: auto;
    margin-top: 10px;
    width: 95%;
}

.Titile p{
    font-size: 30px;
    font-weight: 600;
}

.MainSection {
    margin: auto;
    width: 95%;
}

.BaseInfoDiv {
    background-color: white;
    padding: 10px;
    border-radius: 12px;
    border: 2px black solid;
    display: flex;
    flex-wrap: wrap;
}

.EachFormDiv {
    padding-top: 20px;
    padding-left: 20px;
    font-size: 25px;
}

.EachFormDiv p{
    font-family: "Bebas Neue", sans-serif;
}

.EachFormDiv100 p{
    font-family: "Bebas Neue", sans-serif;
}

.EachFormDiv100 {
    padding-top: 20px;
    padding-left: 20px;
    font-size: 25px;
    width: 100%;
}

.LoginIDText {
    margin-left: -2px;
    padding-left: 5px;
    font-size: 20px;
    border: 3px black solid;
    border-radius: 5px;
    width: 300px;
}

.LoginIDText:focus {
    background-color: rgb(244, 247, 255);;
}

.StatusRadio {
    margin-left: 20px;
    padding-left: 5px;
    font-size: 20px;
    border: 3px black solid;
    border-radius: 5px;
}

.RadioDiv {
    margin-left: -20px;
}

.RemarkText {
    margin-left: -2px;
    padding-left: 5px;
    font-size: 20px;
    border: 3px black solid;
    border-radius: 5px;
    width: 90%;
}

.RemarkText:focus {
    background-color: rgb(244, 252, 255);;
}

.UserTable {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.UserTable td,
.UserTable th {
    border: 1px solid #ddd;
    padding: 8px;
}

.UserTable tr:nth-child(even) {
    background-color: #f2f2f2;
}

.UserTable tr:hover {
    background-color: #ddd;
}

.UserTable th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #04AA6D;
    color: white;
}

.EditUserBtn {
    text-align: center;
}

.EditUserBtn:hover {
    cursor: pointer;
}